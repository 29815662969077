/* eslint-disable @typescript-eslint/no-unused-vars */
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import {
	Button,
	Col,
	Divider,
	Modal,
	Row,
	Select,
	Steps,
	Typography,
	Space,
	Tabs,
	TreeSelect,
	Dropdown,
	Menu,
} from 'antd';
import { Loader, SyncNow, TableActionHeader } from 'components/Global';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import ExportData from 'components/Global/ExportData';
import { TimeLogs } from 'components/TimeActivity';
import CreateTimeSheetModal from 'components/TimeActivity/CreateTimeSheetModal';
import { TimeSheetFormData } from 'components/TimeActivity/CreateTimeSheetModal/types';
import NotFoundEmployeesModal from 'components/TimeActivity/NotFoundEmployeeTimeLogModal';
import { TimeLog } from 'components/TimeActivity/TimeLogs/types';
import TimeSheet from 'components/TimeActivity/TimeSheet';
import ValidTimeSheetModal from 'components/TimeActivity/ValidTimeSheetModal';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getConfiguration } from 'redux/action/configurationAction';
import { getCustomRulesAction } from 'redux/action/customRuleAction';
import {
	addTimeLog,
	getTimeLogs,
	getTimeLogsPaginate,
	splitTimeLogAction,
	updateTimeLog,
} from 'redux/action/timeLogsAction';
import { deleteApiWithData, getApi, postApi } from 'redux/apis';
import { timeActivitySyncDate } from 'redux/slice/companySlice';
import { clearTimeLogRedux } from 'redux/slice/timeLogSlice';
import { AppDispatch } from 'redux/store';
import { useDebounce } from 'use-debounce';
import { AddSvg, CloseSvg } from 'utils/svgs';
import {
	checkPermission,
	divideTimeBySlots,
	divideTimeInHalf,
	hasText,
	makeTotalTime,
	timeDifference,
	toastText,
} from 'utils/utils';
import { v4 as uuidV4 } from 'uuid';
import * as XLSX from 'xlsx';
import styles from './index.module.scss';
import UploadFileForm from 'components/UploadTimeActivity/UploadFileForm';
import HeaderMappingForm from 'components/UploadTimeActivity/HeaderMappingForm';
import DataMappingForm from 'components/UploadTimeActivity/DataMappingForm';
import DataReviewTable from 'components/UploadTimeActivity/DataReviewTable';

import Papa from 'papaparse';

const TimeActivity = () => {
	// Inits
	const dispatch = useDispatch<AppDispatch>();

	const [queryParams, setQueryParams] = useSearchParams();

	const [fileClassValues, setFileClassValues] = useState<any[]>([]);
	const [fileEmployeeValues, setFileEmployeeValues] = useState<any[]>([]);
	const [fileCustomerValues, setFileCustomerValues] = useState<any[]>([]);
	const [historyOfMapping, setHistoryOfMapping] = useState({
		classMapping: [],
		employeeMapping: [],
		customerMapping: [],
	});

	const { optionData: classList } = useSelector((state: any) => state.class);
	const { data: employees } = useSelector((state: any) => state.employees);

	const [isAccountClosed, setIsAccountClosed] = useState(false);

	const { data: customRules } = useSelector((state: any) => state.customRules);
	const [isTimeLogUploadModalOpen, setIsTimeLogUploadModalOpen] =
		useState(false);
	const { optionData: customerOptions } = useSelector(
		(state: any) => state.customer
	);

	const payPeriodsData = useSelector(
		(state: any) => state?.payPeriods?.optionData
	);

	const [timeLogsData, setTimeLogsData] = useState<any>([]);
	const [openTables, setOpenTables] = useState<string[]>([]);

	const [isDataMappingModalOpen, setIsDataMappingModalOpen] = useState(false);
	// const [isTimeLog, isSetTimeLog] = useState(true);
	// const [hoursUnder, setHoursUnder] = useState(false);
	const [timeLogAdding, setTimeLogAdding]: any = useState([]);
	const [timeLogsDataCopy, setTimeLogsDataCopy] = useState<any>([]);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [parentTimeActivityId, setParentTimeActivityId] = useState(null);
	const [selectedTimeLog, setSelectedTimeLog] = useState<any>(null);
	const [payPeriod, setPayPeriod] = useState<any>(null);

	const [selectedPayPeriod, setSelectedPayPeriod] = useState<any>(
		queryParams.get('payPeriod') || localStorage.getItem('payPeriod') || null
	);
	const [isTimeLogs, setIsTimeLogs] = useState('1');

	const [openCreateTimeSheet, setOpenCreateTimeSheet] = useState(false);
	const [openValidateTimeSheet, setOpenValidateTimeSheet] = useState(false);
	const [timeSheetData, setTimeSheetData] = useState<any>(null);

	const [openWarningModal, setOpenWarningModal] = useState(false);
	const [notFoundEmployees, setNotFoundEmployees] = useState<any[]>([]);
	const [headers, setHeaders] = useState<any[]>([]);
	const [data, setData] = useState<any[]>([]);

	//filters, search and sort
	const [currentPage, setCurrentPage] = useState(1);

	const [applySearch, setApplySearch] = useState(false);

	const [searchValue, setSearchValue] = useState('');
	const [debounceSearchValue] = useDebounce(searchValue, 1000);

	const [filterValue, setFilterValue] = useState({});

	const [sortName, setSortName] = useState('');
	const [sortValue, setSortValue] = useState('');
	const [sortHandler, setSortHandler] = useState({
		employeeId: 'asc',
		customerId: 'asc',
		classId: 'asc',
		activityDate: 'desc',
	});

	const [isLoadingCustomRules, setIsLoadingCustomRules] = useState(false);

	const [selectHeadersValues, setHeadersSelectValues] = useState({
		activityDate: [],
		employee: [],
		customer: [],
		class: [],
		hours: [],
	});

	const [selectHeadersValidation, setHeadersSelectValidation] = useState({
		activityDate: false,
		employee: false,
		customer: false,
		class: false,
		hours: false,
	});

	const [lastSyncTime, setLastSyncTime] = useState(new Date());
	const [syncLoading, setSyncLoading] = useState(false);
	const [payPeriodData, setPayPeriodData] = useState<any>({});
	const [error] = useState({
		activityDate: false,
		hrs: false,
	});
	const {
		isFirstTimeLoading,
		data: timeLogs,
		currentDatePayPeriod,
		hasActivityData,
	} = useSelector((state: any) => state.timeLogs);

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state.companies
	);

	const payPeriods = useSelector((state: any) => state?.payPeriods?.optionData);

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	const isViewTimeLogsPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Time Logs',
		permission: ['view'],
	});

	const isCreateTimeSheetPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Time Sheets',
			permission: ['add'],
		}
	);

	const isViewTimeSheetPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Time Sheets',
		permission: ['view'],
	});

	const isUpdateTimeSheetPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Time Sheets',
			permission: ['edit'],
		}
	);

	const isAddTimeLogPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Time Logs',
		permission: ['add'],
	});

	const isEditTimeLogPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Time Logs',
		permission: ['edit'],
	});

	const isViewCustomRulePermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Custom Rules',
			permission: ['view'],
		}
	);

	const timeActivityLastSyncDate = useSelector(
		(state: any) =>
			state?.companies?.selectedCompanyDetails?.company
				?.timeActivitiesLastSyncDate
	);

	const selectedCompanyId = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.companyId
	);

	const { data: configurationsData } = useSelector(
		(state: any) => state?.configuration
	);

	const { closingDate } = useSelector((state: any) => state?.closingDate);
	const [fileList, setFileList] = useState<any[]>([]);
	const [current, setCurrent] = useState(0);

	const navigate = useNavigate();

	const getMappingHistory = () => {
		const fetchTimelogMappingHistory = async () => {
			try {
				// Make the API call to get timelog mapping history
				const response = await postApi(
					'/time-activities/timelog-mapping-history',
					{
						companyId: localStorage.getItem('companyId'),
					}
				);

				// Destructure the mappings from the response
				const {
					headerMapping,
					classMapping,
					employeeMapping,
					customerMapping,
				} = response.data?.data?.mappingData || {};

				if (headerMapping) {
					// Create a new object to store the validated header mappings
					const validatedHeaderMapping: any = {};

					// Loop through each key in the `headerMapping`
					for (const key in headerMapping) {
						// Get the value from headerMapping
						const mappingValues = headerMapping[key];

						// Filter the mapping values based on their presence in `headers`
						const filteredValues = mappingValues.filter((value: any) =>
							headers.includes(value)
						);

						// If no values are matched, add the key with an empty array
						// Otherwise, add the key with the matched values
						validatedHeaderMapping[key] =
							filteredValues.length > 0 ? filteredValues : [];
					}

					// Update the state with the validated header mapping
					setHeadersSelectValues(validatedHeaderMapping);
				}

				// Update historyOfMapping state
				setHistoryOfMapping({
					classMapping: classMapping || [], // Fallback to empty array if classMapping is undefined
					employeeMapping: employeeMapping || [], // Fallback to empty array if employeeMapping is undefined
					customerMapping: customerMapping || [], // Fallback to empty array if customerMapping is undefined
				});
			} catch (error) {
				console.error('Error fetching timelog mapping history:', error);
				// Handle error (optional: display a message to the user)
			}
		};

		fetchTimelogMappingHistory();
	}; // Empty dependency array means this effect runs once on mount

	useEffect(() => {
		getMappingHistory();
	}, [headers]); // Dependency array includes headers

	useEffect(() => {
		getMappingHistory();
	}, []);

	useEffect(() => {
		if (currentDatePayPeriod) {
			setSelectedPayPeriod(currentDatePayPeriod);
			setQueryParams({ payPeriod: currentDatePayPeriod });
		}
	}, [currentDatePayPeriod]);

	useEffect(() => {
		document.title = 'CostAllocation Pro - Time Activity';
		dispatch(
			getCustomRulesAction({
				status: 'Active',
			})
		);
		return () => {
			dispatch(clearTimeLogRedux());
		};
	}, []);

	useEffect(() => {
		setLastSyncTime(timeActivityLastSyncDate);
	}, [timeActivityLastSyncDate]);

	useEffect(() => {
		if (selectedPayPeriod) {
			dispatch(
				getConfiguration({
					payPeriodId: selectedPayPeriod,
				})
			);
		}
	}, [selectedPayPeriod]);
	const [file, setFile] = useState<any>(null);

	// const propsUpload = {
	// 	name: 'file',
	// 	accept: '.csv,.xls,.xlsx',
	// 	maxCount: 1,
	// 	fileList: fileList,
	// 	beforeUpload: (file: any) => {
	// 		return false;
	// 	},
	// 	onChange(info: any) {
	// 		const { file } = info;
	// 		if (file?.size) {
	// 			const isLt1M = file?.size / 1024 / 1024 < 1;

	// 			if (!isLt1M) {
	// 				toastText('Image must smaller than 1MB!', 'error');
	// 				return;
	// 			}
	// 		}

	// 		if (!isRemoving) {
	// 			setFile(info.file);
	// 			setFileList([info.file]);
	// 		} else {
	// 			isRemoving = false;
	// 			setFile(null);
	// 			setFileList([]);
	// 		}
	// 	},
	// 	onDrop: () => {
	// 		setFile(null);
	// 		setFileList([]);
	// 	},
	// 	onRemove: () => {
	// 		isRemoving = true;
	// 	},
	// };

	const handleContinue = (values: any) => {
		if (!values.file) {
			toastText('Please upload a file first', 'error');
			return;
		}

		const file = values.file;
		const fileSizeInMB = file.size / (1024 * 1024);
		if (fileSizeInMB <= 2) {
			// Updated size check to 2MB
			setFile(file);
			const fileType = file.name.split('.').pop();
			if (fileType) {
				switch (fileType.toLowerCase()) {
					case 'csv':
						processCSV(file);
						break;
					case 'xls':
					case 'xlsx':
						processExcel(file);
						break;
					default:
						toastText('Unsupported file type', 'error');
						setFile(null);
				}
			}
		} else {
			console.log('size limit crossed');
			toastText('File size must be smaller than 2MB', 'error');
			setFile(null);
		}
	};

	const processCSV = (file: any) => {
		const reader = new FileReader();

		reader.onload = (event: any) => {
			const text = event.target.result;

			// Use PapaParse to properly handle CSV parsing, including commas in values
			Papa.parse(text, {
				header: true, // Automatically uses the first row as headers
				skipEmptyLines: true, // Skips empty lines
				complete: (result: any) => {
					if (result && result.data && result.data.length > 0) {
						const headers = Object.keys(result.data[0]);
						const data = result.data;

						setHeaders(headers);
						setData(data);
					} else {
						toastText('CSV file is empty or invalid!', 'error');
					}
				},
				error: () => {
					toastText('Failed to parse the CSV file!', 'error');
				},
			});
		};

		reader.onerror = () => {
			toastText('Failed to read the file!', 'error');
		};

		reader.readAsText(file);
	};

	const processExcel = (file: File) => {
		const reader = new FileReader();

		reader.onload = (event: ProgressEvent<FileReader>) => {
			if (event.target && event.target.result) {
				const data = new Uint8Array(event.target.result as ArrayBuffer);
				const workbook = XLSX.read(data, { type: 'array' });

				// Assuming the first sheet
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];

				// Convert the sheet to JSON
				const jsonData = XLSX.utils.sheet_to_json<string[]>(worksheet, {
					header: 1,
				});

				// Find the first non-empty row to use as headers
				let headers: string[] = [];
				let startIndex = 0;

				for (let i = 0; i < jsonData.length; i++) {
					const row = jsonData[i];
					if (
						row.some(
							(cell) => cell !== null && cell !== undefined && cell !== ''
						)
					) {
						headers = row; // Found headers
						startIndex = i + 1; // Start processing data from the next row
						break;
					}
				}

				if (headers.length > 0) {
					// Process rows after headers
					const data = jsonData.slice(startIndex).reduce((acc, row) => {
						// Add your custom condition here to filter out specific rows
						// For example, hide rows where the first column is empty
						if (
							!row.some(
								(cell) => cell !== null && cell !== undefined && cell !== ''
							)
						) {
							return acc; // Skip empty rows
						}

						const rowData = headers.reduce(
							(
								obj: { [key: string]: string },
								header: string,
								index: number
							) => {
								obj[header] = row[index] || '';
								return obj;
							},
							{}
						);
						acc.push(rowData);
						return acc;
					}, [] as { [key: string]: string }[]);

					setHeaders(headers);
					console.log('data_dsb', data);
					setData(data);
				} else {
					toastText('Excel file is empty or invalid!', 'error');
				}
			}
		};

		reader.readAsArrayBuffer(file);
	};

	// Perform initial function call

	// For add a single time log to timelog list
	const addTimeLogHandler = () => {
		const timeLog: any = {
			id: Math.random().toString(),
			timeActivityId: Math.random().toString(),
			classId: null,
			className: null,
			customerId: null,
			customerName: null,
			hours: '00',
			minute: '00',
			employeeId: null,
			actualTimeLog: '00:00',
			activityDate: null, //null for now
			employeeName: null,
			isAdding: true,
			SplitTimeActivities: [],
		};
		setTimeLogAdding([timeLog, ...timeLogAdding]);
		setTimeLogsData([timeLog, ...timeLogsData]);
	};

	const applyCustomRules = async () => {
		try {
			setIsLoadingCustomRules(true);
			await getApi('/time-activities/apply-custom-rules', {
				payPeriodId: selectedPayPeriod,
				companyId: localStorage.getItem('companyId'),
			});

			await initialCall();
		} catch (error: any) {
			toastText(error.message, 'error');
		} finally {
			setIsLoadingCustomRules(false);
		}
	};

	const clearPayPeriod = () => {
		setSelectedPayPeriod(null);
		setQueryParams({});
	};

	// For update a single time log to timelog list
	const updateTimeLogHandler = (id: string, name: string, value: any) => {
		const copyTimeLogs: any = JSON.parse(JSON.stringify(timeLogsData));
		const timeLogIndex = timeLogsData.findIndex(
			(singleEl: any) => singleEl.id === id
		);
		const addingTimeLogIndex = timeLogAdding.findIndex(
			(singleEl: any) => singleEl.id === id
		);
		if (name === 'customerName') {
			copyTimeLogs[timeLogIndex]['customerName'] = value.children;
			copyTimeLogs[timeLogIndex]['customerId'] = value.value;
			copyTimeLogs[timeLogIndex]['errorCustomer'] = false;
			copyTimeLogs[timeLogIndex]['isCustomerSelected'] = true;
			if (addingTimeLogIndex !== -1) {
				timeLogAdding[timeLogIndex]['customerName'] = value.children;
				timeLogAdding[timeLogIndex]['customerId'] = value.value;
				timeLogAdding[timeLogIndex]['errorCustomer'] = false;
				timeLogAdding[timeLogIndex]['isCustomerSelected'] = true;
			}
		} else if (name === 'className') {
			copyTimeLogs[timeLogIndex]['classId'] = value.value;
			copyTimeLogs[timeLogIndex]['className'] = value.children;
			copyTimeLogs[timeLogIndex]['errorClass'] = false;
			copyTimeLogs[timeLogIndex]['isClassSelected'] = true;
			if (addingTimeLogIndex !== -1) {
				timeLogAdding[timeLogIndex]['classId'] = value.value;
				timeLogAdding[timeLogIndex]['className'] = value.children;
				timeLogAdding[timeLogIndex]['errorClass'] = false;
				timeLogAdding[timeLogIndex]['isClassSelected'] = true;
			}
		} else if (name === 'employeeName') {
			copyTimeLogs[timeLogIndex]['employeeId'] = value.value;
			copyTimeLogs[timeLogIndex]['employeeName'] = value.children;
			copyTimeLogs[timeLogIndex]['errorEmployee'] = false;
			if (addingTimeLogIndex !== -1) {
				timeLogAdding[timeLogIndex]['employeeId'] = value.value;
				timeLogAdding[timeLogIndex]['employeeName'] = value.children;
				timeLogAdding[timeLogIndex]['errorEmployee'] = false;
			}
		} else if (name === 'hrs') {
			copyTimeLogs[timeLogIndex]['actualTimeLog'] = value;
			copyTimeLogs[timeLogIndex]['errorHrs'] = false;
			if (addingTimeLogIndex !== -1) {
				timeLogAdding[timeLogIndex]['actualTimeLog'] = value;
				timeLogAdding[timeLogIndex]['errorHrs'] = false;
			}
		} else if (name === 'activityDate') {
			copyTimeLogs[timeLogIndex][name] = value;
			copyTimeLogs[timeLogIndex]['errorActivityDate'] = false;
			if (addingTimeLogIndex !== -1) {
				timeLogAdding[timeLogIndex]['customerName'] = value.children;
				timeLogAdding[timeLogIndex]['customerId'] = value.value;
				timeLogAdding[timeLogIndex]['errorCustomer'] = false;
				timeLogAdding[timeLogIndex]['isCustomerSelected'] = true;
			}
		} else {
			copyTimeLogs[timeLogIndex][name] = value;
		}
		setTimeLogsData(copyTimeLogs);
	};

	// Disable handler
	const disableHandler = (id: string) => {
		const copyTimeLogs: any = JSON.parse(JSON.stringify(timeLogsData));
		const timeLogIndex = timeLogsData.findIndex(
			(singleEl: any) => singleEl.id === id
		);

		copyTimeLogs[timeLogIndex]['isDisabled'] = false;
		copyTimeLogs[timeLogIndex]['isEditing'] = true;

		setTimeLogsData(copyTimeLogs);
	};

	// Split Time log Disable Handler
	const splitDisableHandler = (
		timeActivity: any,
		id: string,
		index: number
	) => {
		const copyTimeLogs: any = JSON.parse(JSON.stringify(timeLogsData));
		const timeLogIndex = timeLogsData.findIndex(
			(singleEl: any) => singleEl.id === timeActivity.id
		);
		copyTimeLogs[timeLogIndex]['SplitTimeActivities'][index].isEditing = true;
		setTimeLogsData(copyTimeLogs);
	};

	//NOTE: In future if user want to apply custom rules on manual split un comment the below code

	// const matchCustomRule = (timeActivity: any) => {
	// 	let matchedRule: any = null;

	// 	for (const rule of customRules) {
	// 		if (
	// 			rule.criteria.employeeId === timeActivity.employeeId &&
	// 			hasText(rule.criteria.classId) &&
	// 			hasText(rule.criteria.customerId)
	// 		) {
	// 			if (
	// 				rule.criteria.operator === 'AND' &&
	// 				rule.criteria.classId == timeActivity.classId &&
	// 				rule.criteria.customerId == timeActivity.customerId
	// 			) {
	// 				matchedRule = rule;
	// 				break;
	// 			}

	// 			if (
	// 				rule.criteria.operator === 'OR' &&
	// 				(rule.criteria.classId == timeActivity.classId ||
	// 					rule.criteria.customerId == timeActivity.customerId)
	// 			) {
	// 				matchedRule = rule;
	// 				break;
	// 			}
	// 		}

	// 		if (
	// 			rule.criteria.employeeId === timeActivity.employeeId &&
	// 			hasText(rule.criteria.classId) &&
	// 			!hasText(rule.criteria.customerId) &&
	// 			rule.criteria.classId == timeActivity.classId
	// 		) {
	// 			matchedRule = rule;
	// 			break;
	// 		}

	// 		if (
	// 			rule.criteria.employeeId === timeActivity.employeeId &&
	// 			hasText(rule.criteria.customerId) &&
	// 			!hasText(rule.criteria.classId) &&
	// 			rule.criteria.customerId == timeActivity.customerId
	// 		) {
	// 			matchedRule = rule;
	// 			break;
	// 		}

	// 		if (
	// 			rule.criteria.employeeId === timeActivity.employeeId &&
	// 			!hasText(rule.criteria.customerId) &&
	// 			!hasText(rule.criteria.classId)
	// 		) {
	// 			matchedRule = rule;
	// 			break;
	// 		}
	// 	}

	// 	return matchedRule;
	// };

	// For split the time log
	const splitTimeLogHandler = (singleTimeLog: any) => {
		// const ruleData = matchCustomRule(singleTimeLog);

		splitWithoutRule(singleTimeLog);
		// if (!ruleData) {
		// 	return;
		// }

		// splitWithRule(singleTimeLog, ruleData);
	};

	const splitWithoutRule = (singleTimeLog: any) => {
		const copyTimeLogs: any = JSON.parse(JSON.stringify(timeLogsData));
		const splitActivity = [];
		const timeLogIndex: any = timeLogsData.findIndex(
			(singleEl: any) => singleEl.id === singleTimeLog.id
		);

		const timeLog = copyTimeLogs[timeLogIndex];
		const hoursMinutesData = divideTimeInHalf(
			Number(timeLog.actualTimeLog.split(':')[0]),
			Number(timeLog.actualTimeLog.split(':')[1])
		);
		const minuteInInteger = Number.isInteger(
			Number(timeLog.actualTimeLog.split(':')[1]) / 2 || 0
		);

		for (let index = 0; index < 2; index++) {
			let actualMinute = hoursMinutesData.halfMinutes;
			if (index > 0 && !minuteInInteger) {
				actualMinute += 1;
			}
			splitActivity.push({
				key: uuidV4(),
				id: timeLog.id,
				timeActivityId: timeLog?.timeActivityId,
				classId: timeLog?.classId || null,
				className: timeLog?.className || null,
				customerId: timeLog?.customerId,
				customerName: timeLog?.customerName,
				hours: hoursMinutesData.halfHours,
				minute: actualMinute,
				activityDate: timeLog?.activityDate,
				actualTimeLog: `${
					hoursMinutesData?.halfHours?.toString()?.padStart(2, '0') || '00'
				}:${actualMinute?.toString()?.padStart(2, '0') || '00'}`,
				isAdding: false,
				isEditing: false,
				errorHrs: false,
				errorActivityDate: false,
				errorCustomer: false,
				errorClass: false,
				errorEmployee: false,
				isAutoSplit: false,
				isClassReadOnly: false,
				isCustomerReadOnly: false,
				customRuleId: null,
			});
		}

		copyTimeLogs[timeLogIndex]['SplitTimeActivities'] = splitActivity;
		copyTimeLogs[timeLogIndex]['isSplitting'] = true;
		setTimeLogsData(copyTimeLogs);
	};

	// const splitWithRule = (singleTimeLog: any, ruleData: any) => {
	// 	const copyTimeLogs: any = JSON.parse(JSON.stringify(timeLogsData));
	// 	const splitActivity = [];
	// 	const timeLogIndex: any = timeLogsData.findIndex(
	// 		(singleEl: any) => singleEl.id === singleTimeLog.id
	// 	);

	// 	const timeLog = copyTimeLogs[timeLogIndex];
	// 	const hoursMinutesData = divideTimeAsPerPercentage(
	// 		Number(timeLog.actualTimeLog.split(':')[0]),
	// 		Number(timeLog.actualTimeLog.split(':')[1]),
	// 		ruleData.actions
	// 	);

	// 	for (let index = 0; index < ruleData?.actions?.length; index++) {
	// 		const action = ruleData?.actions[index];

	// 		const actualTimeLog = minToHours(hoursMinutesData[index]);

	// 		splitActivity.push({
	// 			key: uuidV4(),
	// 			id: timeLog.id,
	// 			timeActivityId: timeLog?.timeActivityId,
	// 			classId: action?.classId || null,
	// 			className: action?.className || null,
	// 			customerId: action?.customerId,
	// 			customerName: action?.customerName || null,
	// 			hours: actualTimeLog.split(':')[0],
	// 			minute: actualTimeLog.split(':')[1],
	// 			activityDate: timeLog?.activityDate,
	// 			actualTimeLog,
	// 			isAdding: false,
	// 			isEditing: false,
	// 			errorHrs: false,
	// 			errorActivityDate: false,
	// 			errorCustomer: false,
	// 			errorClass: false,
	// 			errorEmployee: false,
	// 			isAutoSplit: true,
	// 			isClassReadOnly: hasText(action.classId),
	// 			isCustomerReadOnly: hasText(action.customerId),
	// 			customRuleId: ruleData?.id,
	// 		});
	// 	}

	// 	copyTimeLogs[timeLogIndex]['SplitTimeActivities'] = splitActivity;
	// 	copyTimeLogs[timeLogIndex]['isSplitting'] = true;
	// 	setTimeLogsData(copyTimeLogs);
	// };

	// For convert it into supported time log data
	const formateTimeLogData = () => {
		const formattedTimeLogs = timeLogs.map((singleTimeLog: TimeLog) => {
			// const timeLogIndex = timeLogsData.findIndex(
			// 	(singleTimeLogFromState: any) =>
			// 		singleTimeLog.id === singleTimeLogFromState.id
			// );

			const splitTimeLogs = singleTimeLog.SplitTimeActivities.map(
				(singleSplitTimeLog: any) => {
					return {
						key: uuidV4(),
						id: singleSplitTimeLog.id,
						timeActivityId: singleSplitTimeLog?.timeActivityId,
						classId: singleSplitTimeLog?.classId || null,
						className: singleSplitTimeLog?.className || null,
						customerId: singleSplitTimeLog?.customerId,
						customerName: singleSplitTimeLog?.customerName,
						hours: singleSplitTimeLog?.hours,
						minute: singleSplitTimeLog?.minute,
						activityDate: singleSplitTimeLog?.activityDate,
						actualTimeLog: `${singleSplitTimeLog?.hours}:${singleSplitTimeLog?.minute}`,
						isAdding: false,
						isEditing: false,
						errorHrs: false,
						errorActivityDate: false,
						errorCustomer: false,
						errorClass: false,
						errorEmployee: false,
						isAutoSplit: singleSplitTimeLog?.isAutoSplit || false,
						isClassReadOnly: singleSplitTimeLog?.isClassReadOnly || false,
						isCustomerReadOnly: singleSplitTimeLog?.isCustomerReadOnly || false,
						customRuleId: singleSplitTimeLog?.customRuleId || null,
					};
				}
			);
			return {
				id: singleTimeLog.id,
				timeActivityId: singleTimeLog?.timeActivityId,
				classId: singleTimeLog?.classId || null,
				className: singleTimeLog?.className || null,
				customerId: singleTimeLog?.customerId,
				customerName: singleTimeLog?.customerName,
				hours: singleTimeLog?.hours,
				minute: singleTimeLog?.minute,
				companyId: singleTimeLog?.companyId,
				employeeId: singleTimeLog?.employeeId,
				activityDate: singleTimeLog?.activityDate,
				employeeName: singleTimeLog?.employee?.fullName,
				actualTimeLog: `${singleTimeLog?.hours}:${singleTimeLog?.minute}`,
				isAdding: false,
				isEditing: false,
				errorHrs: false,
				isSplitting: false,
				isSplitEditing: false,
				errorActivityDate: false,
				errorCustomer: false,
				errorClass: false,
				errorEmployee: false,
				SplitTimeActivities: splitTimeLogs,
				isOver: singleTimeLog?.isOver,
				overHours: singleTimeLog?.overHours,
				overMinutes: singleTimeLog?.overMinutes,
				// isDisabled:
				// 	!singleTimeLog?.classId || !singleTimeLog?.customerId ? true : false,
				isClassNull: singleTimeLog?.classId ? false : true,
				isClassSelected: false,
				isCustomerNull: singleTimeLog?.customerId ? false : true,
				isCustomerSelected: false,
				isAccountClosed: singleTimeLog.isAccountClosed,
			};
			// if (timeLogIndex !== -1) {
			// 	return timeLogsData[timeLogIndex];
			// } else {
			// }
		});
		setTimeLogsData([
			...timeLogAdding,
			...JSON.parse(JSON.stringify(formattedTimeLogs)),
		]);
		setTimeLogsDataCopy([
			...timeLogAdding,
			...JSON.parse(JSON.stringify(formattedTimeLogs)),
		]);
	};

	// For save on final save
	const saveAddedItemHandler = (activityId: string) => {
		const copyTimeLogs = JSON.parse(JSON.stringify(timeLogsData));
		const timeLogIndex = timeLogsData.findIndex(
			(singleEl: any) => singleEl.id === activityId
		);
		const {
			id,
			timeActivityId,
			employeeName,
			isAdding,
			errorHrs,
			errorActivityDate,
			errorCustomer,
			errorClass,
			errorEmployee,
			actualTimeLog,
			...others
		} = copyTimeLogs[timeLogIndex];
		const timeArray = actualTimeLog.split(':');
		others['hours'] = timeArray[0]?.padStart(2, '0') || '00';
		others['minute'] = timeArray[1]?.padStart(2, '0') || '00';
		if (!others.hours && !others.minute) {
			copyTimeLogs[timeLogIndex].errorHrs = true;
		}
		if (others.hours == '00' && others.minute == '00') {
			copyTimeLogs[timeLogIndex].errorHrs = true;
		}
		if (!others.activityDate) {
			copyTimeLogs[timeLogIndex].errorActivityDate = true;
		}
		if (
			!others.customerId &&
			configurationsData?.isCustomerRequiredForJournal
		) {
			copyTimeLogs[timeLogIndex].errorCustomer = true;
		}
		if (!others.classId && configurationsData?.isClassRequiredForJournal) {
			copyTimeLogs[timeLogIndex].errorClass = true;
		}
		if (!hasText(others.employeeId)) {
			copyTimeLogs[timeLogIndex].errorEmployee = true;
		}
		setTimeLogsData(copyTimeLogs);
		if (
			others.activityDate &&
			(!configurationsData?.isCustomerRequiredForJournal ||
				others.customerId) &&
			(!configurationsData?.isClassRequiredForJournal || others.classId) &&
			others.employeeId
		) {
			dispatch(addTimeLog({ ...others, payPeriodId: selectedPayPeriod }))
				.unwrap()
				.then((res) => {
					if (res?.id) {
						copyTimeLogs[timeLogIndex].isAdding = false;
						copyTimeLogs[timeLogIndex].id = res.id;
						setTimeLogsData(copyTimeLogs);
						setTimeLogAdding((prev: any) => {
							return prev.filter(
								(timeLogSingle: any) => timeLogSingle.id !== activityId
							);
						});
					}
				});
		}

		// if (
		// 	others.activityDate &&
		// 	others.classId &&
		// 	others.customerId &&
		// 	others.employeeId
		// ) {
		// 	dispatch(addTimeLog(others))
		// 		.unwrap()
		// 		.then((res) => {
		// 			if (res?.id) {
		// 				copyTimeLogs[timeLogIndex].isAdding = false;
		// 				copyTimeLogs[timeLogIndex].id = res.id;
		// 				setTimeLogsData(copyTimeLogs);
		// 				setTimeLogAdding((prev: any) => {
		// 					return prev.filter(
		// 						(timeLogSingle: any) => timeLogSingle.id !== activityId
		// 					);
		// 				});
		// 			}
		// 		});
		// }
	};
	const updateSavedTimeLog = async (
		activityId: string,
		time: string,
		classId?: string,
		className?: string,
		customerId?: string,
		customerName?: string
	) => {
		const timeArray = time.split(':');
		const copyTimeLogs = JSON.parse(JSON.stringify(timeLogsData));
		const timeLogIndex = timeLogsData.findIndex(
			(singleEl: any) => singleEl.id === activityId
		);

		const data = {
			timeActivityId: copyTimeLogs[timeLogIndex].id,
			hours: timeArray[0]?.padStart(2, '0') || '00',
			minute: timeArray[1]?.padStart(2, '0') || '00',
			classId: classId,
			className: className,
			customerId: customerId,
			customerName: customerName,
		};
		if (!customerId && configurationsData?.isCustomerRequiredForJournal) {
			copyTimeLogs[timeLogIndex].errorCustomer = true;
		}
		if (!classId && configurationsData?.isClassRequiredForJournal) {
			copyTimeLogs[timeLogIndex].errorClass = true;
		}

		// setTimeLogsData(copyTimeLogs);

		if (!classId && configurationsData?.isClassRequiredForJournal) {
			delete data['classId'];
		}
		if (!className && configurationsData?.isClassRequiredForJournal) {
			delete data['className'];
		}
		if (!customerId && configurationsData?.isCustomerRequiredForJournal) {
			delete data['customerId'];
		}
		if (!customerName && configurationsData?.isCustomerRequiredForJournal) {
			delete data['customerName'];
		}
		// setTimeLogsData(copyTimeLogs);
		if (classId && className && customerId && customerName) {
			copyTimeLogs[timeLogIndex].hours = timeArray[0]?.padStart(2, '0') || '00';
			copyTimeLogs[timeLogIndex].minute =
				timeArray[1]?.padStart(2, '0') || '00';
			if (
				!copyTimeLogs[timeLogIndex].hours &&
				!copyTimeLogs[timeLogIndex].minute
			) {
				copyTimeLogs[timeLogIndex].errorHrs = true;
				// setTimeLogsData(copyTimeLogs);
			}

			// if (
			// 	copyTimeLogs[timeLogIndex].hours == '00' &&
			// 	copyTimeLogs[timeLogIndex].minute == '00'
			// ) {
			// 	copyTimeLogs[timeLogIndex].errorHrs = true;
			// // 	setTimeLogsData(copyTimeLogs);
			// }

			if (
				copyTimeLogs[timeLogIndex].activityDate &&
				copyTimeLogs[timeLogIndex].classId &&
				copyTimeLogs[timeLogIndex].customerId &&
				copyTimeLogs[timeLogIndex].employeeId
			) {
				const regex = /^0*:(0*)$/;
				const regex2 = /^0*$/;
				if (
					regex.test(copyTimeLogs[timeLogIndex].actualTimeLog) ||
					regex2.test(copyTimeLogs[timeLogIndex].actualTimeLog)
				) {
					copyTimeLogs[timeLogIndex].actualTimeLog = '00:00';
				} else if (regex2.test(copyTimeLogs[timeLogIndex].hours)) {
					copyTimeLogs[
						timeLogIndex
					].actualTimeLog = `00:${copyTimeLogs[timeLogIndex].minute}`;
					copyTimeLogs[timeLogIndex].hours = '00';
					data['hours'] = '00';
				} else if (!hasText(copyTimeLogs[timeLogIndex].actualTimeLog)) {
					copyTimeLogs[timeLogIndex].actualTimeLog = '00:00';
					copyTimeLogs[timeLogIndex].hours = '00';
					copyTimeLogs[timeLogIndex].minute = '00';
				} else if (!copyTimeLogs[timeLogIndex].actualTimeLog.includes(':')) {
					copyTimeLogs[
						timeLogIndex
					].actualTimeLog = `${copyTimeLogs[timeLogIndex].actualTimeLog}:00`;
				} else if (
					copyTimeLogs[timeLogIndex].actualTimeLog.split(':')[0] == ''
				) {
					copyTimeLogs[
						timeLogIndex
					].actualTimeLog = `00${copyTimeLogs[timeLogIndex].actualTimeLog}`;
				} else if (
					copyTimeLogs[timeLogIndex].actualTimeLog.split(':')[1] == ''
				) {
					copyTimeLogs[
						timeLogIndex
					].actualTimeLog = `${copyTimeLogs[timeLogIndex].actualTimeLog}00`;
				}
				copyTimeLogs[timeLogIndex].isClassNull = false;
				copyTimeLogs[timeLogIndex].isCustomerNull = false;
				// setTimeLogsData(copyTimeLogs);
				await dispatch(updateTimeLog(data));
				copyTimeLogs[timeLogIndex]['isEditing'] = false;
			}
		} else {
			if (!data.classId) {
				data.className = '';
			}

			if (!data.customerId) {
				data.customerName = '';
			}

			const _data: any = {
				...data,
				classId: classId ? classId : null,
				className: classId ? className : null,
				customerId: customerId ? customerId : null,
				customerName: customerId ? customerName : null,
			};

			const regex = /^0*:(0*)$/;
			const regex2 = /^0*0$/;
			if (regex.test(time)) {
				copyTimeLogs[timeLogIndex].errorHrs = true;
				// setTimeLogsData(copyTimeLogs);
			} else if (regex2.test(time)) {
				copyTimeLogs[timeLogIndex].errorHrs = true;
				// setTimeLogsData(copyTimeLogs);
			} else if (time === '') {
				copyTimeLogs[timeLogIndex].errorHrs = true;
				// setTimeLogsData(copyTimeLogs);
			} else {
				if (
					!configurationsData?.isClassRequiredForJournal ||
					!configurationsData?.isCustomerRequiredForJournal
				) {
					if (configurationsData?.isClassRequiredForJournal && !_data.classId) {
						setTimeLogsData(copyTimeLogs);
						return;
					}

					if (
						configurationsData?.isCustomerRequiredForJournal &&
						!_data.customerId
					) {
						setTimeLogsData(copyTimeLogs);
						return;
					}

					if (!data.classId) {
						copyTimeLogs[timeLogIndex].className = null;
						// copyTimeLogs[timeLogIndex].isDisabled = true;
					}

					if (!data.customerId) {
						copyTimeLogs[timeLogIndex].customerName = null;
						// copyTimeLogs[timeLogIndex].isDisabled = true;
					}

					await dispatch(updateTimeLog(_data));
					copyTimeLogs[timeLogIndex]['isEditing'] = false;
				}
			}
		}
		setTimeLogsData(copyTimeLogs);
	};

	const updateSplitActivityOnBlur = (
		id: string,
		name: string,
		value: any,
		index: any
	) => {
		const hours = value.split(':')[0]?.toString()?.padStart(2, '0');
		const minutes = value.split(':')[1]?.toString()?.padStart(2, '0');
		const copyTimeLogs: any = JSON.parse(JSON.stringify(timeLogsData));
		const timeLogIndex = timeLogsData.findIndex(
			(singleEl: any) => singleEl.id === id
		);

		const regex = /^0*:(0*)$/;
		const regex2 = /^0*$/;

		if (regex.test(value) || regex2.test(value)) {
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][index].actualTimeLog =
				'00:00';
		} else if (regex2.test(hours)) {
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][
				index
			].actualTimeLog = `00:${minutes}`;
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][index].hours = '00';
		} else if (!hasText(value)) {
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][index].actualTimeLog =
				'00:00';
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][index].hours = '00';
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][index].minute = '00';
		} else if (!value.includes(':')) {
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][
				index
			].actualTimeLog = `${hours}:00`;
		} else if (value.split(':')[0] == '') {
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][
				index
			].actualTimeLog = `00:${minutes}`;
		} else if (value.includes(':')[1] == '') {
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][
				index
			].actualTimeLog = `${hours}:00`;
		}
		setTimeLogsData(copyTimeLogs);
	};

	const updateSplitActivityHandler = (
		id: string,
		name: string,
		value: any,
		index: any
	) => {
		const copyTimeLogs: any = JSON.parse(JSON.stringify(timeLogsData));
		const timeLogIndex = timeLogsData.findIndex(
			(singleEl: any) => singleEl.id === id
		);

		// Check if child activity hours are not equals to total hours
		let totalMinutes = 0;
		copyTimeLogs[timeLogIndex]?.SplitTimeActivities.forEach(
			(singleSplitTimeActivity: any, arrIndex: number) => {
				if (name === 'hrs') {
					if (index != arrIndex) {
						totalMinutes +=
							Number(singleSplitTimeActivity.actualTimeLog.split(':')[0]) * 60 +
							Number(singleSplitTimeActivity.actualTimeLog.split(':')[1]);
					}
				} else {
					totalMinutes +=
						Number(singleSplitTimeActivity.actualTimeLog.split(':')[0]) * 60 +
						Number(singleSplitTimeActivity.actualTimeLog.split(':')[1]);
				}
			}
		);

		// totalMinutes =
		// 	totalMinutes +
		// 	Number(value.split(':')[0]) * 60 +
		// 	Number(value.split(':')[1]);

		if (name === 'hrs') {
			if (value?.includes(':')) {
				totalMinutes =
					totalMinutes +
					Number(value?.split(':')[0]) * 60 +
					Number(value?.split(':')[1]);
			} else {
				totalMinutes += Number(value?.split(':')[0]) * 60;
			}
		}

		if (name === 'className') {
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][index]['classId'] =
				value.value;
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][index]['className'] =
				value.children;
		} else if (name === 'hrs') {
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][index][
				'actualTimeLog'
			] = value;
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][index]['errorHrs'] =
				false;
		} else if (name === 'customerName') {
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][index]['customerName'] =
				value.children;
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][index]['customerId'] =
				value.value;
		}

		let actualMinutes = 0;

		actualMinutes =
			Number(copyTimeLogs[timeLogIndex].actualTimeLog.split(':')[0]) * 60 +
			Number(copyTimeLogs[timeLogIndex].actualTimeLog.split(':')[1]);

		if (totalMinutes != actualMinutes) {
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'][index]['errorHrs'] =
				true;
		} else {
			copyTimeLogs[timeLogIndex]['SplitTimeActivities'].forEach(
				(singleActivity: any) => {
					singleActivity['errorHrs'] = false;
				}
			);
		}
		setTimeLogsData(copyTimeLogs);
	};

	// For create the time sheet
	const openCreateTimeSheetModelHandler = async () => {
		setOpenCreateTimeSheet(true);
		try {
			const res = await getApi('/time-sheet/by-payPeriod', {
				payPeriodId: selectedPayPeriod,
				companyId: localStorage.getItem('companyId'),
			});
			if (res?.data?.data) {
				setTimeSheetData(res.data.data);
				return;
			}
			setTimeSheetData(null);
		} catch (error) {
			setOpenCreateTimeSheet(false);
		}
	};

	const openValidateTimeSheetModalHandler = async () => {
		try {
			const response = await postApi('/time-sheet/validate', {
				payPeriodId: selectedPayPeriod,
				companyId: localStorage.getItem('companyId'),
			});

			if (response?.data?.data?.isValid) {
				openCreateTimeSheetModelHandler();
			} else {
				setOpenValidateTimeSheet(true);
			}
		} catch (error) {
			setOpenValidateTimeSheet(false);
		}
	};

	const openTimeLogUploadModalHandler = async () => {
		setIsTimeLogUploadModalOpen(true);
	};

	const deleteTimeLogHandler = (timeLog: any) => {
		// initialCall();
		setTimeLogAdding((prev: any) => {
			return prev.filter(
				(timeLogSingle: any) => timeLogSingle.id !== timeLog.id
			);
		});
		setTimeLogsData((prevState: any) => {
			return prevState.filter(
				(singleTimeLog: any) => singleTimeLog.id !== timeLog.id
			);
		});
		setTimeLogsDataCopy((prevState: any) => {
			return prevState.filter(
				(singleTimeLog: any) => singleTimeLog.id !== timeLog.id
			);
		});
	};

	// For cancel the split operation
	const cancelSplitHandler = (timeLog: any) => {
		const copyTimeLogs = JSON.parse(JSON.stringify(timeLogsData));
		const timeLogIndex = timeLogsData.findIndex(
			(singleEl: any) => singleEl.id === timeLog.id
		);
		copyTimeLogs[timeLogIndex]['SplitTimeActivities'] =
			timeLogsDataCopy[timeLogIndex].SplitTimeActivities;
		copyTimeLogs[timeLogIndex]['isSplitting'] = false;
		setTimeLogsData(JSON.parse(JSON.stringify(copyTimeLogs)));
	};

	const saveSplitTimeLogHandler = (timeLog: any, index?: number) => {
		const copyTimeLogs = JSON.parse(JSON.stringify(timeLogsData));
		const timeLogIndex = timeLogsData.findIndex(
			(singleEl: any) => singleEl.id === timeLog.id
		);
		copyTimeLogs[timeLogIndex]['isSplitting'] = false;
		copyTimeLogs[timeLogIndex]['isSplitEditing'] = true;
		const splitTimeLogForApi = timeLog.SplitTimeActivities.map(
			(singleSplitTimeLog: any) => {
				return {
					classId: singleSplitTimeLog.classId,
					className: singleSplitTimeLog.className,
					customerId: singleSplitTimeLog.customerId,
					customerName: singleSplitTimeLog.customerName,
					hours: singleSplitTimeLog.actualTimeLog.split(':')[0] || '00',
					minute: singleSplitTimeLog.actualTimeLog.split(':')[1] || '00',
					activityDate: singleSplitTimeLog.activityDate,
					isAutoSplit: singleSplitTimeLog?.isAutoSplit || false,
					isClassReadOnly: singleSplitTimeLog?.isClassReadOnly || false,
					isCustomerReadOnly: singleSplitTimeLog?.isCustomerReadOnly || false,
					customRuleId: singleSplitTimeLog?.customRuleId || null,
				};
			}
		);
		dispatch(
			splitTimeLogAction({
				timeActivityData: splitTimeLogForApi,
				parentActivityId: timeLog.id,
				employeeId: timeLog.employeeId,
			})
		)
			.unwrap()
			.then((data: any) => {
				setTimeLogsData(JSON.parse(JSON.stringify(copyTimeLogs)));
				setTimeLogsDataCopy(JSON.parse(JSON.stringify(copyTimeLogs)));
				toastText('Time logs updated successfully', 'success');
			})
			.catch((error) => console.error('Error', error));
	};

	const editSplitTimeLogHandler = (timeLog: any) => {
		const copyTimeLogs = JSON.parse(JSON.stringify(timeLogsData));
		const timeLogIndex = timeLogsData.findIndex(
			(singleEl: any) => singleEl.id === timeLog.id
		);
		copyTimeLogs[timeLogIndex]['isSplitting'] = true;
		copyTimeLogs[timeLogIndex]['isSplitEditing'] = false;
		setTimeLogsData(copyTimeLogs);
	};

	// For perform the sub split operation
	const subSplitHandler = (
		splitTimeActivity: any,
		timeLog: any,
		index: number
	) => {
		const copyTimeLogs: any = JSON.parse(JSON.stringify(timeLogsData));
		const allSplitTimeActivity = timeLog.SplitTimeActivities;
		const timeLogIndex = timeLogsData.findIndex(
			(singleEl: any) => singleEl.id === timeLog.id
		);
		const { dividedHours, dividedMinutes }: any = divideTimeBySlots(
			Number(timeLog.hours),
			Number(timeLog.minute),
			allSplitTimeActivity.length + 1
		);
		const timeArray = [];
		////////////////////// OLD CODE START ////////////////////

		// const hoursMinutesData = divideTimeInHalf(
		// 	Number(splitTimeActivity.actualTimeLog.split(':')[0]),
		// 	Number(splitTimeActivity.actualTimeLog.split(':')[1])
		// );

		// const minuteInInteger = Number.isInteger(
		// 	Number(splitTimeActivity.actualTimeLog.split(':')[1]) / 2 || 0
		// );

		// allSplitTimeActivity[index]['hours'] = hoursMinutesData.halfHours;
		// allSplitTimeActivity[index]['minute'] = hoursMinutesData.halfMinutes;
		// allSplitTimeActivity[index]['actualTimeLog'] = `${
		// 	hoursMinutesData?.halfHours?.toString()?.padStart(2, '0') || '00'
		// }:${hoursMinutesData?.halfMinutes?.toString()?.padStart(2, '0') || '00'}`;

		// allSplitTimeActivity.splice(index + 1, 0, {
		// 	id: Math.random().toString(),
		// 	timeActivityId: splitTimeActivity?.timeActivityId,
		// 	classId: splitTimeActivity?.classId || null,
		// 	className: splitTimeActivity?.className || null,
		// 	customerId: splitTimeActivity?.customerId,
		// 	customerName: splitTimeActivity?.customerName,
		// 	hours: hoursMinutesData.halfHours,
		// 	minute: !minuteInInteger
		// 		? hoursMinutesData.halfMinutes + 1
		// 		: hoursMinutesData.halfMinutes,
		// 	activityDate: splitTimeActivity?.activityDate,
		// 	actualTimeLog: `${
		// 		hoursMinutesData?.halfHours?.toString()?.padStart(2, '0') || '00'
		// 	}:${
		// 		!minuteInInteger
		// 			? (hoursMinutesData.halfMinutes + 1).toString()?.padStart(2, '0') ||
		// 			  '00'
		// 			: hoursMinutesData.halfMinutes?.toString()?.padStart(2, '0') || '00'
		// 	}`,
		// 	isAdding: false,
		// 	errorHrs: false,
		// 	errorActivityDate: false,
		// 	errorCustomer: false,
		// 	errorClass: false,
		// 	errorEmployee: false,
		// });

		// copyTimeLogs[timeLogIndex]['SplitTimeActivities'] = allSplitTimeActivity;
		// copyTimeLogs[timeLogIndex]['isSplitting'] = true;
		// setTimeLogsData(copyTimeLogs);

		////////////////////// OLD CODE END ////////////////////
		const splitTimeActivities = allSplitTimeActivity.map(
			(singleSplitTimeActivity: any) => {
				return {
					...singleSplitTimeActivity,
					hours: dividedHours,
					minute: dividedMinutes,
					actualTimeLog: `${
						dividedHours?.toString()?.padStart(2, '0') || '00'
					}:${dividedMinutes?.toString()?.padStart(2, '0') || '00'}`,
				};
			}
		);

		for (let index = 0; index < allSplitTimeActivity.length + 1; index++) {
			timeArray.push({ hours: dividedHours, minutes: dividedMinutes });
		}

		const totalTime = makeTotalTime(timeArray);
		const timeDifferenceObj = timeDifference(
			{ hours: Number(timeLog.hours), minutes: Number(timeLog.minute) },
			totalTime
		);
		const lastActivityTime = makeTotalTime([timeArray[0], timeDifferenceObj]);

		splitTimeActivities.push({
			id: Math.random().toString(),
			timeActivityId: splitTimeActivity?.timeActivityId,
			classId: splitTimeActivity?.classId || null,
			className: splitTimeActivity?.className || null,
			customerId: splitTimeActivity?.customerId,
			customerName: splitTimeActivity?.customerName,
			activityDate: splitTimeActivity?.activityDate,
			hours: lastActivityTime.hours,
			minute: lastActivityTime.minutes,
			actualTimeLog: `${
				lastActivityTime.hours?.toString()?.padStart(2, '0') || '00'
			}:${lastActivityTime.minutes?.toString()?.padStart(2, '0') || '00'}`,
			isAdding: false,
			errorHrs: false,
			errorActivityDate: false,
			errorCustomer: false,
			errorClass: false,
			errorEmployee: false,
			isAutoSplit: splitTimeActivity?.isAutoSplit,
			isClassReadOnly: splitTimeActivity?.isClassReadOnly,
			isCustomerReadOnly: splitTimeActivity?.isCustomerReadOnly,
			customRuleId: splitTimeActivity?.customRuleId,
		});

		copyTimeLogs[timeLogIndex]['SplitTimeActivities'] = splitTimeActivities;
		copyTimeLogs[timeLogIndex]['isSplitting'] = true;
		copyTimeLogs[timeLogIndex]['SplitTimeActivities'].forEach(
			(singleActivity: any) => {
				singleActivity['errorHrs'] = false;
			}
		);
		setTimeLogsData(copyTimeLogs);
	};

	// For remove the sub split timeLog
	const removeSubSplitHandler = async (
		splitTimeActivity: any,
		timeLog: any,
		index: number
	) => {
		const timeArray = [];
		const copyTimeLogs: any = JSON.parse(JSON.stringify(timeLogsData));
		const allSplitTimeActivity = timeLog.SplitTimeActivities;
		const timeLogIndex = timeLogsData.findIndex(
			(singleEl: any) => singleEl.id === timeLog.id
		);
		if (allSplitTimeActivity.length === 2) {
			setIsDeleteModalOpen(true);
			setParentTimeActivityId(timeLog.id);
			setSelectedTimeLog(timeLog);

			// copyTimeLogs[timeLogIndex]['SplitTimeActivities'] = [];
			// setTimeLogsData(copyTimeLogs);
			return;
		}
		const { dividedHours, dividedMinutes }: any = divideTimeBySlots(
			Number(timeLog.hours),
			Number(timeLog.minute),
			allSplitTimeActivity.length - 1
		);

		// ////////////////// OLD CODE START //////////////////

		// console.log(copyTimeLogs, timeLogIndex);
		// const addToActivityIndex = index > 0 ? index - 1 : index + 1;
		// const addToActivity = allSplitTimeActivity[addToActivityIndex];

		// const hoursMinutesData = addTimeToTime(
		// 	Number(allSplitTimeActivity[index]['actualTimeLog'].split(':')[0]),
		// 	Number(allSplitTimeActivity[index]['actualTimeLog'].split(':')[1]),
		// 	Number(addToActivity['actualTimeLog'].split(':')[0]),
		// 	Number(addToActivity['actualTimeLog'].split(':')[1])
		// );
		// copyTimeLogs[timeLogIndex]['SplitTimeActivities'][addToActivityIndex][
		// 	'actualTimeLog'
		// ] = `${hoursMinutesData?.newHours?.toString()?.padStart(2, '0') || '00'}:${
		// 	hoursMinutesData?.newMinutes?.toString()?.padStart(2, '0') || '00'
		// }`;

		// ////////////////// OLD CODE END //////////////////

		for (let index = 0; index < allSplitTimeActivity.length - 1; index++) {
			timeArray.push({ hours: dividedHours, minutes: dividedMinutes });
		}

		const totalTime = makeTotalTime(timeArray);
		const timeDifferenceObj = timeDifference(
			{ hours: Number(timeLog.hours), minutes: Number(timeLog.minute) },
			totalTime
		);

		const lastActivityTime = makeTotalTime([timeArray[0], timeDifferenceObj]);

		const splitTimeActivities = allSplitTimeActivity.map(
			(singleSplitTimeActivity: any) => {
				return {
					...singleSplitTimeActivity,
					hours: dividedHours,
					minute: dividedMinutes,
					actualTimeLog: `${
						dividedHours?.toString()?.padStart(2, '0') || '00'
					}:${dividedMinutes?.toString()?.padStart(2, '0') || '00'}`,
				};
			}
		);
		splitTimeActivities.pop();

		splitTimeActivities[splitTimeActivities.length - 1]['hours'] =
			lastActivityTime.hours;
		splitTimeActivities[splitTimeActivities.length - 1]['minute'] =
			lastActivityTime.minutes;
		splitTimeActivities[splitTimeActivities.length - 1]['actualTimeLog'] = `${
			lastActivityTime.hours?.toString()?.padStart(2, '0') || '00'
		}:${lastActivityTime.minutes?.toString()?.padStart(2, '0') || '00'}`;
		copyTimeLogs[timeLogIndex]['SplitTimeActivities'] = splitTimeActivities;
		copyTimeLogs[timeLogIndex]['isSplitting'] = true;
		setTimeLogsData(copyTimeLogs);
	};

	// const filterUnderOverHandler = (value: boolean) => {
	// 	setHoursUnder(value);
	// };

	const initialCall = async (isPageable?: boolean) => {
		try {
			if (isPageable) {
				setCurrentPage((prev: any) => prev + 1);
			} else {
				setCurrentPage(1);
			}

			const query: any = {
				page: isPageable ? currentPage + 1 : 1,
				limit: 20,
				search: searchValue,
				type: sortValue === 'ascend' ? 'asc' : 'desc',
				payPeriodId: selectedPayPeriod,
				closingDate,
				...filterValue,
			};

			if (!hasText(searchValue)) {
				delete query.search;
			}

			if (!hasText(selectedPayPeriod)) {
				delete query.payPeriodId;
			}

			if (!sortName || !sortValue) {
				delete query.sort;
				delete query.type;
			}

			if (sortName === 'customer') {
				query['sort'] = 'customerName';
			}
			if (sortName === 'employee') {
				query['sort'] = 'employee';
			}
			if (sortName === 'class') {
				query['sort'] = 'className';
			}
			if (sortName === 'activityDate') {
				query['sort'] = 'activityDate';
			}

			if (isPageable) {
				await dispatch(getTimeLogsPaginate({ ...query }));
				return;
			}
			const resultAction: any = await dispatch(getTimeLogs({ ...query }));

			if (getTimeLogs.rejected.match(resultAction)) {
				navigate('/login');
			}
		} catch (error: any) {
			if (error?.response?.data?.error?.status == 401) {
				navigate('/login');
				window.location.reload();
			} // Handle unauthorized error
		}
	};

	const showCustomRulesHandler = () => {
		setQueryParams({
			payPeriodId: selectedPayPeriod,
			openDrawer: 'true',
		});
		localStorage.setItem('settings', 'Custom Rules');
	};

	const handleImportedActivity = () => {
		initialCall();
		formateTimeLogData();
	};

	useEffect(() => {
		formateTimeLogData();
	}, [timeLogs]);

	// For fetch initial function data
	useEffect(() => {
		if (isTimeLogs === '1') {
			initialCall();
		}
	}, [isTimeLogs]);

	useEffect(() => {
		if (
			isTimeLogs === '1' &&
			selectedCompanyDetails &&
			hasActivityData &&
			payPeriods &&
			payPeriods.length
		) {
			if (payPeriods) {
				const payPeriodData = payPeriods.find(
					(e: any) => e.id === selectedPayPeriod
				);
				if (
					payPeriodData &&
					payPeriodData.companyId === selectedCompanyDetails.companyId
				) {
					handleClearFilter();
				} else if (
					payPeriods[payPeriods.length - 1].companyId ===
					selectedCompanyDetails.companyId
				) {
					setSelectedPayPeriod(payPeriods[payPeriods.length - 1]?.id || null);
					handleClearFilter();
				} else {
					setSelectedPayPeriod(null);
					handleClearFilter();
				}
			}
		}
		// if (isTimeLogs === '1' && hasActivityData) {
		// }
	}, [selectedCompanyDetails, payPeriods]);
	// useEffect(() => {
	// 	initialCall();
	// }, []);

	useEffect(() => {
		if (applySearch && isTimeLogs === '1') {
			initialCall();
		}
	}, [
		applySearch,
		debounceSearchValue,
		filterValue,
		selectedPayPeriod,
		sortName,
		sortValue,
		isTimeLogs,
	]);

	useEffect(() => {
		const data = payPeriods.find(
			(singlePayPeriod: any) => singlePayPeriod.id === selectedPayPeriod
		);
		setPayPeriodData(data);
	}, [selectedPayPeriod, payPeriods]);

	const handleCancel = () => {
		setParentTimeActivityId(null);
		setSelectedTimeLog(null);
		setIsDeleteModalOpen(false);
	};

	const handleOk = async () => {
		setIsDeleteModalOpen(false);
	};

	const deleteHandler = async () => {
		await deleteApiWithData(`/split-time-activity/all`, {
			timeActivityId: parentTimeActivityId,
		});
		const copyTimeLogs: any = JSON.parse(JSON.stringify(timeLogsData));
		const data = copyTimeLogs.map((singleTimeLog: any) => {
			if (singleTimeLog.id === parentTimeActivityId) {
				return {
					...singleTimeLog,
					SplitTimeActivities: [],
				};
			} else {
				return singleTimeLog;
			}
		});
		const timeLogIndex = copyTimeLogs.findIndex(
			(singleEl: any) => singleEl.id === selectedTimeLog.id
		);
		data[timeLogIndex].isSplitting = false;
		setTimeLogsData(data);
		setOpenTables((prevState) => {
			return prevState.filter((single) => single !== selectedTimeLog.id);
		});
		setParentTimeActivityId(null);
		setSelectedTimeLog(null);
		setIsDeleteModalOpen(false);
	};

	const handleCreateTimeSheet = async (data: TimeSheetFormData) => {
		try {
			const res = await postApi('/time-sheet', {
				...data,
				payPeriodId: selectedPayPeriod,
				companyId: localStorage.getItem('companyId'),
			});
			if (res.data?.data) {
				toastText(
					`TimeSheet ${timeSheetData ? 'Updated' : 'Created'} Successfully`,
					'success'
				);
				setTimeSheetData(res.data.data.timeSheet);
				setOpenCreateTimeSheet(false);
				setIsTimeLogs('2');
				setQueryParams({ payPeriod: selectedPayPeriod });
				if (
					res.data.data.notFoundEmployeesName &&
					res.data.data.notFoundEmployeesName.length
				) {
					setOpenWarningModal(true);
					setNotFoundEmployees(res.data.data.notFoundEmployeesName);
				}
			}
		} catch (error: any) {
			toastText(error?.message, 'error');
		}
	};

	const handleChangePayPeriod = async (payPeriod: any) => {
		setPayPeriod(payPeriod);
		setSelectedPayPeriod(payPeriod?.id || null);
		if (payPeriod?.id) {
			setQueryParams({ payPeriod: payPeriod.id });
		} else {
			setQueryParams({});
		}
		if (payPeriod) {
			try {
				const res = await getApi('/time-sheet/by-payPeriod', {
					payPeriodId: payPeriod.id,
					companyId: localStorage.getItem('companyId'),
				});
				if (res?.data?.data) {
					setTimeSheetData(res.data.data);
					return;
				}
				setTimeSheetData(null);
			} catch (error) {
				console.log(error);
			}
		}
	};

	useEffect(() => {
		if (payPeriods && payPeriods.length) {
			handleChangePayPeriodById(selectedPayPeriod);
		}
	}, [selectedPayPeriod, payPeriods]);

	const handleChangePayPeriodById = async (payPeriodId: any) => {
		const newPayPeriod = payPeriods.find(
			(singlePayPeriod: any) => singlePayPeriod.id == payPeriodId
		);
		handleChangePayPeriod(newPayPeriod);
	};

	const changeTimeSheetTab = (isTimeLog: boolean) => {
		if (isTimeLog) {
			// setSelectedPayPeriod(localStorage.getItem('payPeriod'));
			// setApplySearch(true);
			setIsTimeLogs('1');
		} else {
			setIsTimeLogs('2');
		}
		setApplySearch(true);
		setSelectedPayPeriod(localStorage.getItem('payPeriod') as string);
		setQueryParams({ payPeriod: localStorage.getItem('payPeriod') as string });
	};

	const handleClearFilter = () => {
		setApplySearch(true);
		setSearchValue('');
		setFilterValue({});
		// setSelectedPayPeriod(null);
		setQueryParams({});
		setSortName('');
		setSearchValue('');
	};

	const validateHeaders = () => {
		let isValid = true;
		const newValidationState: any = { ...selectHeadersValidation };

		Object.keys(selectHeadersValues).forEach((key: any) => {
			if (
				selectHeadersValues[key as keyof typeof selectHeadersValues].length ===
				0
			) {
				newValidationState[key] = true;
				isValid = false;
			} else {
				newValidationState[key] = false;
			}
		});

		setHeadersSelectValidation(newValidationState);
		return isValid;
	};

	const handleHeadersSubmitButton = () => {
		const validationPassed = validateHeaders();
		if (validationPassed) {
			console.log('All fields are valid.');
			setIsDataMappingModalOpen(true);
			// Add your logic here when validation passes
		} else {
			console.log('Validation failed.');
			// Add your logic here when validation fails
		}
	};
	const openTableHandler = (test: any, timeLog: any, flag: any) => {
		let splitActivityArr: any = [];
		if (flag === 'open') {
			if (!openTables.includes(timeLog.id)) {
				setOpenTables((prevData) => [...prevData, timeLog.id]);
			} else {
				setOpenTables((prevState: any) => {
					return prevState.filter(
						(singleTimeLogId: string) => singleTimeLogId !== timeLog.id
					);
				});
			}
		} else if (flag === 'close') {
			setOpenTables((prevState: any) => {
				return prevState.filter(
					(singleTimeLogId: string) => singleTimeLogId !== timeLog.id
				);
			});
		} else if (flag === 'split') {
			setOpenTables((prevData) => {
				splitActivityArr = [...prevData, timeLog.id];
				return [...prevData, timeLog.id];
			});
		}
	};

	let items;

	if (isViewTimeLogsPermission && isViewTimeSheetPermission) {
		items = [
			{
				key: '1',
				label: 'Time Logs',
				children: (
					<>
						<div className={styles['time-logs-tab-export']}>
							{customRules && customRules.length ? (
								<button
									className={`btn-black ${styles['user-table__action--button']}`}
									onClick={applyCustomRules}
								>
									{isLoadingCustomRules ? (
										<img
											src="assets/gifs/loading-black.gif"
											height={30}
										/>
									) : (
										<p>Apply Custom Rules</p>
									)}
								</button>
							) : null}
							<div className={styles['add-timelog-button']}>
								{!isAccountClosed && isAddTimeLogPermission ? (
									<button
										className={`btn-black ${styles['user-table__action--button']}`}
										onClick={addTimeLogHandler}
									>
										<AddSvg />
										<p>Add Time log</p>
									</button>
								) : null}
							</div>
							<div className={styles['dynamic-table__filter-sync--sync']}>
								{(isAddTimeLogPermission || isEditTimeLogPermission) && (
									<>
										<SyncNow
											isLastSyncNeeded={true}
											syncDate={lastSyncTime}
											handleSync={() => syncNowHandler()}
											tooltip=""
											key={1}
											isLoading={syncLoading}
										/>
										{timeLogs.length > 0 && <div className={styles['pipe']} />}
									</>
								)}
								{timeLogs.length > 0 && (
									<ExportData
										params={{
											search: searchValue,
											filter: filterValue,
											payPeriodId: selectedPayPeriod,
											payPeriodData: payPeriodData,
											sortName: sortName,
											sortValue: sortValue,
										}}
										moduleName="timeActivity"
									/>
								)}
							</div>
						</div>
						<div className={styles['time-activity__logs']}>
							<TimeLogs
								openTables={openTables}
								setOpenTables={openTableHandler}
								deleteTimeLogHandler={deleteTimeLogHandler}
								timeLogs={timeLogsData}
								updateTimeLogHandler={updateTimeLogHandler}
								saveAddedItemHandler={saveAddedItemHandler}
								updateSavedTimeLog={updateSavedTimeLog}
								disableHandler={disableHandler}
								splitDisableHandler={splitDisableHandler}
								splitTimeLogHandler={splitTimeLogHandler}
								editSplitTimeLogHandler={editSplitTimeLogHandler}
								error={error}
								cancelSplitHandler={cancelSplitHandler}
								saveSplitTimeLogHandler={saveSplitTimeLogHandler}
								updateSplitActivityHandler={updateSplitActivityHandler}
								subSplitHandler={subSplitHandler}
								updateSplitActivityOnBlur={updateSplitActivityOnBlur}
								removeSubSplitHandler={removeSubSplitHandler}
								onChangePayPeriod={(payPeriod: any) => {
									handleChangePayPeriod(payPeriod);
									setApplySearch(true);
								}}
								selectedPayPeriod={selectedPayPeriod}
								clearPayPeriod={clearPayPeriod}
								filterValue={filterValue}
								searchValue={searchValue}
								sortHandler={sortHandler}
								sortName={sortName}
								sortValue={sortValue}
								currentPage={currentPage}
								onChangeSearch={(value: any) => {
									setSearchValue(value);
									setApplySearch(true);
								}}
								onChangeFilter={(key: any, value: any) => {
									setFilterValue({ ...filterValue, [key]: value });
									setApplySearch(true);
								}}
								onSortChange={(name: any, value: any) => {
									setSortName(name);
									setSortValue(value);
									setSortHandler((prevState: any) => {
										return {
											...prevState,
											[name]: value === 'ascend' ? 'asc' : 'desc',
										};
									});
									setApplySearch(true);
								}}
								onChangeScroll={() => {
									initialCall(true);
								}}
								onClearFilter={() => handleClearFilter()}
								onChangeIsAccountClosed={(flag: boolean) => {
									setIsAccountClosed(flag);
								}}
								initialCall={initialCall}
							/>
						</div>
					</>
				),
			},
			{
				key: '2',
				label: 'Time Sheets',
				children: (
					<div className={styles['time-activity__time-sheet']}>
						<TimeSheet
							onChangePayPeriod={(value: any) =>
								handleChangePayPeriodById(value)
							}
							changeTimeSheetTab={changeTimeSheetTab}
						/>
					</div>
				),
			},
		];
	} else if (isViewTimeLogsPermission && !isViewTimeSheetPermission) {
		items = [
			{
				key: '1',
				label: 'Time Logs',
				children: (
					<>
						<div className={styles['time-logs-tab-export']}>
							{customRules && customRules.length ? (
								<button
									className={`btn-black ${styles['user-table__action--button']}`}
									onClick={applyCustomRules}
								>
									{isLoadingCustomRules ? (
										<img
											src="assets/gifs/loading-black.gif"
											height={30}
										/>
									) : (
										<p>Apply Custom Rules</p>
									)}
								</button>
							) : null}
							<div className={styles['add-timelog-button']}>
								{!isAccountClosed && isAddTimeLogPermission ? (
									<button
										className={`btn-black ${styles['user-table__action--button']}`}
										onClick={addTimeLogHandler}
									>
										<AddSvg />
										<p>Add Time log</p>
									</button>
								) : null}
							</div>
							<div className={styles['dynamic-table__filter-sync--sync']}>
								{(isAddTimeLogPermission || isEditTimeLogPermission) && (
									<>
										<SyncNow
											isLastSyncNeeded={true}
											syncDate={lastSyncTime}
											handleSync={() => syncNowHandler()}
											tooltip=""
											key={1}
											isLoading={syncLoading}
										/>
										{timeLogs.length > 0 && <div className={styles['pipe']} />}
									</>
								)}
								{timeLogs.length > 0 && (
									<ExportData
										params={{
											search: searchValue,
											filter: filterValue,
											payPeriodId: selectedPayPeriod,
											payPeriodData: payPeriodData,
											sortName: sortName,
											sortValue: sortValue,
										}}
										moduleName="timeActivity"
									/>
								)}
							</div>
						</div>
						<div className={styles['time-activity__logs']}>
							<TimeLogs
								openTables={openTables}
								setOpenTables={openTableHandler}
								deleteTimeLogHandler={deleteTimeLogHandler}
								timeLogs={timeLogsData}
								updateTimeLogHandler={updateTimeLogHandler}
								saveAddedItemHandler={saveAddedItemHandler}
								updateSavedTimeLog={updateSavedTimeLog}
								disableHandler={disableHandler}
								splitDisableHandler={splitDisableHandler}
								splitTimeLogHandler={splitTimeLogHandler}
								editSplitTimeLogHandler={editSplitTimeLogHandler}
								error={error}
								cancelSplitHandler={cancelSplitHandler}
								saveSplitTimeLogHandler={saveSplitTimeLogHandler}
								updateSplitActivityHandler={updateSplitActivityHandler}
								subSplitHandler={subSplitHandler}
								updateSplitActivityOnBlur={updateSplitActivityOnBlur}
								removeSubSplitHandler={removeSubSplitHandler}
								onChangePayPeriod={(payPeriod: any) => {
									handleChangePayPeriod(payPeriod);
									setApplySearch(true);
								}}
								selectedPayPeriod={selectedPayPeriod}
								clearPayPeriod={clearPayPeriod}
								filterValue={filterValue}
								searchValue={searchValue}
								sortHandler={sortHandler}
								sortName={sortName}
								sortValue={sortValue}
								currentPage={currentPage}
								onChangeSearch={(value: any) => {
									setSearchValue(value);
									setApplySearch(true);
								}}
								onChangeFilter={(key: any, value: any) => {
									setFilterValue({ ...filterValue, [key]: value });
									setApplySearch(true);
								}}
								onSortChange={(name: any, value: any) => {
									setSortName(name);
									setSortValue(value);
									setSortHandler((prevState: any) => {
										return {
											...prevState,
											[name]: value === 'ascend' ? 'asc' : 'desc',
										};
									});
									setApplySearch(true);
								}}
								onChangeScroll={() => {
									initialCall(true);
								}}
								onClearFilter={() => handleClearFilter()}
								onChangeIsAccountClosed={(flag: boolean) => {
									setIsAccountClosed(flag);
								}}
								initialCall={initialCall}
							/>
						</div>
					</>
				),
			},
		];
	} else if (!isViewTimeLogsPermission && isViewTimeSheetPermission) {
		items = [
			{
				key: '2',
				label: 'Time Sheets',
				children: (
					<div className={styles['time-activity__time-sheet']}>
						<TimeSheet
							onChangePayPeriod={(value: any) =>
								handleChangePayPeriodById(value)
							}
							changeTimeSheetTab={changeTimeSheetTab}
						/>
					</div>
				),
			},
		];
	}

	useEffect(() => {
		if (!isViewTimeLogsPermission && isViewTimeSheetPermission) {
			setIsTimeLogs('2');
		}
		if (
			!isViewTimeLogsPermission &&
			!isViewTimeSheetPermission &&
			selectedCompanyPermission
		) {
			navigate('/unauthorized');
		}
	}, [
		isViewTimeLogsPermission,
		isViewTimeSheetPermission,
		selectedCompanyPermission,
	]);

	const syncTimeActivities = async () => {
		setSyncLoading(true);
		try {
			const res = await postApi('/time-activities/sync', {
				companyId: selectedCompanyId,
				payPeriodId: selectedPayPeriod,
			});
			if (res?.data?.data?.notFoundEmployeesName?.length) {
				setOpenWarningModal(true);
				setNotFoundEmployees(res?.data?.data?.notFoundEmployeesName);
			}
			await dispatch(
				getTimeLogs({
					page: 1,
					limit: 20,
					payPeriodId: selectedPayPeriod,
				})
			);
			setSearchValue('');
			setSortName('');
			setApplySearch(false);
			dispatch(timeActivitySyncDate());
			toastText('Time activities synced successfully', 'success');
		} catch (err: any) {
			toastText(err?.response?.data?.message, 'error');
		} finally {
			setSyncLoading(false);
		}
	};

	const syncNowHandler = async () => {
		await syncTimeActivities();
		setLastSyncTime(new Date());
	};
	const handleSelectChange = (key: string, value: any) => {
		setHeadersSelectValues((prevValues) => ({
			...prevValues,
			[key]: value,
		}));
		setHeadersSelectValidation((prevValues) => ({
			...prevValues,
			[key]: value.length ? false : true,
		}));
	};

	const closeMappingModal = () => {
		setData([]);
		setCurrent(0);
		setHeaders([]);
		setFileList([]);
		setFile(null);
		setIsTimeLogUploadModalOpen(false);
		setHeadersSelectValues({
			activityDate: [],
			employee: [],
			customer: [],
			class: [],
			hours: [],
		});
		setHeadersSelectValidation({
			employee: false,
			customer: false,
			class: false,
			activityDate: false,
			hours: false,
		});
	};

	const getDataMapping = () => {
		const processValues = (
			fields: any[],
			mapping: any[],
			setFileValues: (value: any[]) => void,
			valueKey: string,
			nameKey: string
		) => {
			console.log('fields', fields);
			const values = data
				.map((item) =>
					fields
						.map((field) => item[field])
						.filter(
							(value) =>
								value !== null &&
								value?.toString().trim() !== '' &&
								value?.toString().trim() !== '_'
						)
						.join('_')
				)
				.filter((value) => value !== '');

			const uniqueValues = Array.from(new Set(values));

			// Initialize values
			const initialValues = uniqueValues
				.filter((value) => value !== '' && value != null)
				.map((value) => ({
					key: value,
					selectedValue: '',
					[nameKey]: '',
				}));

			// Update with mapping if empty
			const updatedValues = initialValues.map((item) => {
				const map = mapping.find((m) => m.key === item.key);
				if (map) {
					return {
						...item,
						selectedValue:
							item.selectedValue === ''
								? map.selectedValue
								: item.selectedValue,
						[nameKey]: item[nameKey] === '' ? map[nameKey] : item[nameKey],
					};
				}
				return item;
			});

			setFileValues(updatedValues);
		};

		// Process class values
		processValues(
			selectHeadersValues.class,
			historyOfMapping.classMapping,
			setFileClassValues,
			'selectedValue',
			'qboClassName'
		);

		// Process employee values
		processValues(
			selectHeadersValues.employee,
			historyOfMapping.employeeMapping,
			setFileEmployeeValues,
			'selectedValue',
			'qboEmployeeName'
		);

		// Process customer values
		processValues(
			selectHeadersValues.customer,
			historyOfMapping.customerMapping,
			setFileCustomerValues,
			'selectedValue',
			'qboCustomerName'
		);
	};

	const handleTreeSelectChange = (key: string, value: string, data: any) => {
		setFileClassValues((prevValues) =>
			prevValues.map((item) =>
				item.key === key
					? { ...item, selectedValue: value, qboClassName: data[0] }
					: item
			)
		);
	};

	const handleCustomerTreeSelectChange = (
		key: string,
		value: string,
		data: any
	) => {
		setFileCustomerValues((prevValues) =>
			prevValues.map((item) =>
				item.key === key
					? { ...item, selectedValue: value, qboCustomerName: data[0] }
					: item
			)
		);
	};

	const handleEmployeeSelectChange = (
		key: string,
		value: string,
		data: any
	) => {
		setFileEmployeeValues((prevValues) =>
			prevValues.map((item) =>
				item.key === key
					? {
							...item,
							selectedValue: value,
							qboEmployeeName: data?.children,
					  }
					: item
			)
		);
	};

	const handleUploadFileForm = (values: any) => {
		setFile(values.file);
		handleContinue(values);
		console.log(values, current);
	};

	const handleNext = () => {
		if (current == 1) {
			getDataMapping();
		}
		setCurrent(current + 1);
	};
	// const handleHeaderMappingForm = (values: any) => {
	// 	setCurrent(2);
	// };

	// const handleDataMappingForm = () => {
	// 	setCurrent(3);
	// };

	const handleBack = () => {
		setHeadersSelectValues(selectHeadersValues);
		setFileEmployeeValues(fileEmployeeValues);

		setCurrent(current - 1);
	};

	// JSX
	return !isFirstTimeLoading ? (
		<>
			<div className={styles['time-activity']}>
				<div className={styles['time-activity__wrapper']}>
					<div className={styles['time-activity__action-header']}>
						<TableActionHeader title={'Time Activity'}>
							<div className={styles['time-activity__action-header-buttons']}>
								{selectedPayPeriod && isTimeLogs === '1' ? (
									<Dropdown
										overlay={
											<Menu>
												{/* Upload Time Logs */}
												<Menu.Item
													key="upload-time-logs"
													onClick={openTimeLogUploadModalHandler}
												>
													Upload Time Logs
												</Menu.Item>

												{/* Create Timesheet */}
												{!isAccountClosed &&
													isCreateTimeSheetPermission &&
													!timeSheetData && (
														<Menu.Item
															key="create-timesheet"
															onClick={openValidateTimeSheetModalHandler}
														>
															Create Timesheet
														</Menu.Item>
													)}

												{/* Update Timesheet */}
												{!isAccountClosed &&
													isUpdateTimeSheetPermission &&
													timeSheetData && (
														<Menu.Item
															key="update-timesheet"
															onClick={openValidateTimeSheetModalHandler}
														>
															Update Timesheet
														</Menu.Item>
													)}

												{/* Show Custom Rule */}
												{isViewCustomRulePermission && (
													<Menu.Item
														key="show-custom-rule"
														onClick={showCustomRulesHandler}
													>
														Show Custom Rule
													</Menu.Item>
												)}
											</Menu>
										}
										trigger={['click']}
									>
										<button
											className={`btn-black ${styles['user-table__action--button']}`}
										>
											Actions <DownOutlined />
										</button>
									</Dropdown>
								) : null}
							</div>
						</TableActionHeader>
					</div>
					<div className={styles['time-activity__log-ant-sheet']}>
						<Tabs
							defaultActiveKey="2"
							items={items}
							activeKey={isTimeLogs}
							key={isTimeLogs}
							tabBarStyle={{
								paddingLeft: '20px',
								fontSize: '18px',
							}}
							onChange={(e) => {
								changeTimeSheetTab(e === '1');
							}}
						/>
					</div>
				</div>
			</div>
			<ConfirmDelete
				handleCancel={handleCancel}
				handleOk={handleOk}
				isModalOpen={isDeleteModalOpen}
				deleteHandler={deleteHandler}
				deleteMessage="Delete? All split time logs will be deleted."
			/>
			<CreateTimeSheetModal
				open={openCreateTimeSheet}
				onCancel={() => setOpenCreateTimeSheet(false)}
				onSubmitTimeSheet={(data: TimeSheetFormData) =>
					handleCreateTimeSheet(data)
				}
				timeSheetData={{
					name: payPeriod
						? timeSheetData?.name ||
						  `Timesheet(${moment(payPeriod.startDate).format(
								'MM/DD/YYYY'
						  )} - ${moment(payPeriod.endDate).format('MM/DD/YYYY')})`
						: '',
					notes: timeSheetData?.notes || '',
				}}
				isExisting={timeSheetData ? true : false}
			/>
			{openValidateTimeSheet && (
				<ValidTimeSheetModal
					isOpen={openValidateTimeSheet}
					handleCancel={() => setOpenValidateTimeSheet(false)}
					handleSubmit={() => {
						setOpenValidateTimeSheet(false);
						openCreateTimeSheetModelHandler();
					}}
				/>
			)}
			{openWarningModal ? (
				<NotFoundEmployeesModal
					isOpen={openWarningModal}
					handleCancel={() => setOpenWarningModal(false)}
					employees={notFoundEmployees}
				/>
			) : null}
			{isTimeLogUploadModalOpen ? (
				<>
					<Modal
						open={isTimeLogUploadModalOpen}
						maskClosable={false}
						centered
						onCancel={() => {
							setIsTimeLogUploadModalOpen(false);
							setFileList([]);
							setFile(null);
						}}
						width="70%"
						closable={false}
						footer={null}
						className="CustomMultipleFormModal"
					>
						<Row className={styles['time-sheet-modal__header']}>
							<Typography.Title
								level={2}
								className="text-center"
							>
								Upload Time Logs
							</Typography.Title>
							<div
								className={styles['time-sheet-modal__header-delete']}
								onClick={closeMappingModal}
							>
								<CloseSvg />
							</div>
						</Row>
						<>
							<Steps current={current}>
								<Steps.Step
									title="Upload File"
									key={0}
								/>
								<Steps.Step
									title="Header Mapping"
									key={1}
								/>
								<Steps.Step
									title="Data Mapping"
									key={2}
								/>
								<Steps.Step
									title="Data Preview"
									key={3}
								/>
							</Steps>
							{current === 0 ? (
								<UploadFileForm
									handleUploadFileForm={handleUploadFileForm}
									handleNext={handleNext}
									file={file}
								/>
							) : current === 1 ? (
								<HeaderMappingForm
									handleHeaderMappingForm={handleNext}
									handleBack={handleBack}
									headers={headers}
									selectHeadersValues={selectHeadersValues}
									selectHeadersValidation={selectHeadersValidation}
									handleSelectChange={handleSelectChange}
									handleHeadersSubmitButton={handleHeadersSubmitButton}
								/>
							) : current === 2 ? (
								// <PhoneNumber />
								<DataMappingForm
									handleHeaderMappingForm={handleNext}
									handleBack={handleBack}
									headers={headers} // Add missing prop
									fileClassValues={fileClassValues}
									fileEmployeeValues={fileEmployeeValues}
									fileCustomerValues={fileCustomerValues}
									handleTreeSelectChange={handleTreeSelectChange}
									handleEmployeeSelectChange={handleEmployeeSelectChange}
									handleCustomerTreeSelectChange={
										handleCustomerTreeSelectChange
									}
									classList={classList}
									employees={employees}
									customerOptions={customerOptions}
								/>
							) : current === 3 ? (
								<DataReviewTable
									data={data}
									selectHeadersValues={selectHeadersValues}
									handleBack={handleBack}
									handleImportedActivity={handleImportedActivity}
									fileClassValues={fileClassValues}
									fileEmployeeValues={fileEmployeeValues}
									fileCustomerValues={fileCustomerValues}
									classList={classList}
									employees={employees}
									payPeriodsData={payPeriodsData}
									customerOptions={customerOptions}
									closeMappingModal={closeMappingModal}
								/>
							) : (
								<div>
									<h1>Error</h1>
								</div>
							)}
						</>
					</Modal>
				</>
			) : null}
			{/* {headers.length && data.length ? (
				<>
					<Modal
						open={!!(headers.length && data.length)}
						// onCancel={() => {
						// 	setData([]);
						// 	setHeaders([]);
						// }}
						closable={false}
						footer={null}
						className="time-sheet-validate-modal profile__popup"
					>
						<Row className={styles['time-sheet-modal__header']}>
							<div className="userDetailsTitle">
								<b> Map your file with CAP format</b>
							</div>
							<div
								className={styles['time-sheet-modal__header-delete']}
								onClick={closeMappingModal}
							>
								<CloseSvg />
							</div>
						</Row>
						<Row
							className={styles['time-sheet-modal__content']}
							style={{ marginBottom: '10px', marginTop: '10px' }}
						>
							<Col
								span={8}
								style={{ textAlign: 'center', fontWeight: 600 }}
							>
								CAP Headers
							</Col>
							<Col span={6}></Col>
							<Col
								span={10}
								style={{ textAlign: 'center', fontWeight: 600 }}
							>
								File Headers
							</Col>
						</Row>
						<Row
							className={styles['time-sheet-modal__content']}
							style={{ marginBottom: '10px' }}
						>
							<Col span={8}>Activity Date</Col>
							<Col span={6}>{'------>'}</Col>
							<Col span={10}>
								<div style={{ width: '100%' }}>
									<Select
										className={styles['pay-period-filter']}
										onChange={(value) =>
											handleSelectChange('activityDate', value)
										}
										style={{ width: '100%' }}
										size="middle"
										placeholder="Select Mapping"
										maxTagCount={'responsive'}
										// maxCount={1}
										value={selectHeadersValues.activityDate}
										mode="multiple"
										status={selectHeadersValidation.activityDate ? 'error' : ''}
										showSearch
									>
										{headers?.map((header: any, index: number) => {
											return (
												<Select.Option
													value={header}
													key={index}
												>
													{header}
												</Select.Option>
											);
										})}
									</Select>
								</div>
							</Col>
						</Row>
						<Row
							className={styles['time-sheet-modal__content']}
							style={{ marginBottom: '10px' }}
						>
							<Col span={8}>Employee</Col>
							<Col span={6}>{'------>'}</Col>
							<Col span={10}>
								<div style={{ width: '100%' }}>
									<Select
										className={styles['pay-period-filter']}
										onChange={(value) => handleSelectChange('employee', value)}
										style={{ width: '100%' }}
										size="middle"
										placeholder="Select Mapping"
										maxTagCount={'responsive'}
										value={selectHeadersValues.employee}
										mode="multiple"
										status={selectHeadersValidation.employee ? 'error' : ''}
										showSearch
									>
										{headers?.map((header: any, index: number) => {
											return (
												<Select.Option
													value={header}
													key={index}
												>
													{header}
												</Select.Option>
											);
										})}
									</Select>
								</div>
							</Col>
						</Row>
						<Row
							className={styles['time-sheet-modal__content']}
							style={{ marginBottom: '10px' }}
						>
							<Col span={8}>Customer</Col>
							<Col span={6}>{'------>'}</Col>
							<Col span={10}>
								<div style={{ width: '100%' }}>
									<Select
										className={styles['pay-period-filter']}
										onChange={(value) => handleSelectChange('customer', value)}
										style={{ width: '100%' }}
										size="middle"
										placeholder="Select Mapping"
										maxTagCount={'responsive'}
										value={selectHeadersValues.customer}
										mode="multiple"
										status={selectHeadersValidation.customer ? 'error' : ''}
										showSearch
									>
										{headers?.map((header: any, index: number) => {
											return (
												<Select.Option
													value={header}
													key={index}
												>
													{header}
												</Select.Option>
											);
										})}
									</Select>
								</div>
							</Col>
						</Row>
						<Row
							className={styles['time-sheet-modal__content']}
							style={{ marginBottom: '10px' }}
						>
							<Col span={8}>Class</Col>
							<Col span={6}>{'------>'}</Col>
							<Col span={10}>
								<div style={{ width: '100%' }}>
									<Select
										className={styles['pay-period-filter']}
										onChange={(value) => handleSelectChange('class', value)}
										style={{ width: '100%' }}
										size="middle"
										placeholder="Select Mapping"
										value={selectHeadersValues.class}
										maxTagCount={'responsive'}
										mode="multiple"
										status={selectHeadersValidation.class ? 'error' : ''}
										showSearch
									>
										{headers?.map((header: any, index: number) => {
											return (
												<Select.Option
													value={header}
													key={index}
												>
													{header}
												</Select.Option>
											);
										})}
									</Select>
								</div>
							</Col>
						</Row>
						<Row
							className={styles['time-sheet-modal__content']}
							style={{ marginBottom: '10px' }}
						>
							<Col span={8}>Hours</Col>
							<Col span={6}>{'------>'}</Col>
							<Col span={10}>
								<div style={{ width: '100%' }}>
									<Select
										className={styles['pay-period-filter']}
										onChange={
											// () => {
											// }
											(value) => handleSelectChange('hours', value)
										}
										style={{ width: '100%' }}
										size="middle"
										placeholder="Select Mapping"
										value={selectHeadersValues.hours}
										maxTagCount={'responsive'}
										mode="multiple"
										status={selectHeadersValidation.hours ? 'error' : ''}
										showSearch
									>
										{headers?.map((header: any, index: number) => {
											return (
												<Select.Option
													value={header}
													key={index}
												>
													{header}
												</Select.Option>
											);
										})}
									</Select>
								</div>
							</Col>
						</Row>

						<Row style={{ marginTop: '15px' }}>
							<Col
								xs={24}
								md={8}
								lg={8}
								sm={8}
							>
								<Button
									className={`save`}
									onClick={handleHeadersSubmitButton}
								>
									Next
								</Button>
							</Col>
							<Col
								xs={24}
								md={16}
								lg={16}
								sm={16}
							>
								<Button
									className={`cancel`}
									onClick={closeMappingModal}
								>
									Cancel
								</Button>
							</Col>
						</Row>
					</Modal>
				</>
			) : null} */}
			{isDataMappingModalOpen ? (
				<>
					<Modal
						open={isDataMappingModalOpen}
						onCancel={() => {
							setIsDataMappingModalOpen(false);
						}}
						closable={false}
						footer={null}
						width={'1000px'}
						className="time-sheet-validate-modal profile__popup"
					>
						<Row className={styles['time-sheet-modal__header']}>
							<div className="userDetailsTitle">
								<b> Mapping Data with QBO</b>
							</div>
							<div
								className={styles['time-sheet-modal__header-delete']}
								onClick={() => {
									setIsDataMappingModalOpen(false);
								}}
							>
								<CloseSvg />
							</div>
						</Row>
						<Row
							className={styles['time-sheet-modal__content']}
							style={{ marginBottom: '10px', marginTop: '10px' }}
						>
							<Col
								span={8}
								style={{ textAlign: 'center', fontWeight: 600 }}
							>
								CAP (File Data)
							</Col>
							<Col span={6}></Col>
							<Col
								span={10}
								style={{ textAlign: 'center', fontWeight: 600 }}
							>
								QBO Data
							</Col>
						</Row>
						<>
							<Row
								className={styles['time-sheet-modal__content']}
								style={{ marginBottom: '10px', marginTop: '10px' }}
							>
								<Col
									span={24}
									style={{ fontWeight: 600, fontSize: '2rem' }}
								>
									Class
								</Col>
							</Row>
							{fileClassValues?.map(
								(
									item: { key: string; selectedValue: string },
									index: number
								) => (
									<>
										<Row style={{ marginTop: '15px' }}>
											<Col span={8}>{item.key}</Col>
											<Col span={8}></Col>
											<Col span={8}>
												<TreeSelect
													showSearch
													className={styles['search-filter__filter']}
													style={{ width: '100%' }}
													onChange={(value, data: any) =>
														handleTreeSelectChange(item.key, value, data)
													}
													value={item.selectedValue}
													placeholder="Class"
													allowClear={false}
													treeDefaultExpandAll
													size="large"
													treeData={classList}
													filterTreeNode={(inputValue: any, treeNode: any) => {
														// Customize the filtering logic here
														return treeNode.props.title
															.toLowerCase()
															.includes(inputValue.toLowerCase());
													}}
													dropdownRender={(menu) => (
														<>
															{/* {singleMappingSection.type === 'qbCoa' ? ( */}
															<>
																{menu}
																<Divider style={{ margin: '8px 0' }} />
																<Space style={{ padding: '0 8px 4px' }}>
																	<Button
																		type="text"
																		icon={<PlusOutlined />}
																		// onClick={() => {
																		// 	setIsNewAccountModalOpen(true);
																		// }}
																	>
																		Add new QuickBooks Class
																	</Button>
																</Space>
															</>
															{/* ) : (
															<>{menu}</>
														)} */}
														</>
													)}
												/>
											</Col>
										</Row>
									</>
								)
							)}
						</>
						<>
							<Row
								className={styles['time-sheet-modal__content']}
								style={{ marginBottom: '10px', marginTop: '10px' }}
							>
								<Col
									span={24}
									style={{ fontWeight: 600, fontSize: '2rem' }}
								>
									Employee
								</Col>
							</Row>
							{fileEmployeeValues?.map(
								(
									item: { key: string; selectedValue: string },
									index: number
								) => (
									<>
										<Row style={{ marginTop: '15px' }}>
											<Col span={8}>{item.key}</Col>
											<Col span={8}></Col>
											<Col span={8}>
												<Select
													className={styles['search-filter__filter']}
													style={{ width: '100%' }}
													// onChange={
													// 	// (value) =>
													// 	// performFilterHandler('employeeId', value)
													// }
													onChange={(value, data: any) =>
														handleEmployeeSelectChange(item.key, value, data)
													}
													size="large"
													placeholder="Employee"
													value={item?.selectedValue}
													showSearch
													filterOption={(input, option: any) =>
														(option?.children as string)
															.toLowerCase()
															.indexOf(input.toLowerCase()) >= 0
													}
												>
													<Select.Option value="">
														Select Employee
													</Select.Option>
													{employees?.map((employee: any, index: number) => {
														return (
															<Select.Option
																value={employee?.id}
																key={index}
															>
																{employee?.fullName}
															</Select.Option>
														);
													})}
												</Select>
											</Col>
										</Row>
									</>
								)
							)}
						</>
						<>
							<Row
								className={styles['time-sheet-modal__content']}
								style={{ marginBottom: '10px', marginTop: '10px' }}
							>
								<Col
									span={24}
									style={{ fontWeight: 600, fontSize: '2rem' }}
								>
									Customer
								</Col>
							</Row>
							{fileCustomerValues?.map(
								(
									item: { key: string; selectedValue: string },
									index: number
								) => (
									<>
										<Row style={{ marginTop: '15px' }}>
											<Col span={8}>{item.key}</Col>
											<Col span={8}></Col>
											<Col span={8}>
												<TreeSelect
													showSearch
													className={styles['search-filter__filter']}
													style={{ width: '100%' }}
													onChange={(value, data: any) =>
														handleCustomerTreeSelectChange(
															item.key,
															value,
															data
														)
													}
													value={item.selectedValue}
													placeholder="Class"
													allowClear={false}
													treeDefaultExpandAll
													size="large"
													treeData={customerOptions}
													filterTreeNode={(inputValue: any, treeNode: any) => {
														// Customize the filtering logic here
														return treeNode.props.title
															.toLowerCase()
															.includes(inputValue.toLowerCase());
													}}
													dropdownRender={(menu) => (
														<>
															{/* {singleMappingSection.type === 'qbCoa' ? ( */}
															<>
																{menu}
																<Divider style={{ margin: '8px 0' }} />
																<Space style={{ padding: '0 8px 4px' }}>
																	<Button
																		type="text"
																		icon={<PlusOutlined />}
																		// onClick={() => {
																		// 	setIsNewAccountModalOpen(true);
																		// }}
																	>
																		Add new QuickBooks Customer
																	</Button>
																</Space>
															</>
															{/* ) : (
															<>{menu}</>
														)} */}
														</>
													)}
												/>
											</Col>
										</Row>
									</>
								)
							)}
						</>
						<Row style={{ marginTop: '15px' }}>
							<Col
								xs={24}
								md={8}
								lg={8}
								sm={8}
							>
								<Button
									className={`save`}
									onClick={handleContinue}
								>
									Continue
								</Button>
							</Col>
							<Col
								xs={24}
								md={16}
								lg={16}
								sm={16}
							>
								<Button
									className={`cancel`}
									onClick={() => {
										setIsDataMappingModalOpen(false);
									}}
								>
									Cancel
								</Button>
							</Col>
						</Row>
					</Modal>
				</>
			) : null}
		</>
	) : (
		<Loader />
	);
};

export default TimeActivity;
