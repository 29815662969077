import { useState, useEffect } from 'react';
// import EmployeeCostSelector from '../EmployeeCostSelector';
// import HoursSetup from '../HoursSetup';
import { Space, Tabs } from 'antd';
import { SyncNow } from 'components/Global';
import ExportData from 'components/Global/ExportData';
import PayPeriod from 'components/settings/PayPeriods';
import { useDispatch, useSelector } from 'react-redux';
import { checkPermission, toastText } from 'utils/utils';
import DynamicTable from '../Table';
import './index.scss';
import { postApi } from 'redux/apis';
import { AppDispatch } from 'redux/store';
import { getEmployeeCostAction } from 'redux/action/employeeCostAction';
import { employeeCostSyncDate } from 'redux/slice/companySlice';
import styles from './index.module.scss';
import { useSearchParams } from 'react-router-dom';
import { fetchClosingDateAction } from 'redux/action/closingDateAction';
import { updateNotIncludedEmployeeIds } from 'redux/slice/employeeCostSlice';
// import { ImportEmployeeCostCsvModal } from '../ImportEmployeeCostValueModal';
// import { useSelector } from 'react-redux';

const EmployeeCosts = ({
	onChangeKey,
	onChangePayPeriod,
	onChangeCostValue,
}: any) => {
	const [isEmployeeCost, setIsEmployeeCost] = useState('1');
	const [lastSyncTime, setLastSyncTime] = useState(new Date());
	const [syncLoading, setSyncLoading] = useState(false);

	const [queryParams] = useSearchParams();
	const [payPeriodId, setPayPeriodId] = useState<string | null>(
		queryParams.get('payPeriodId') || localStorage.getItem('payPeriod')
	);
	const [searchValue, setSearchValue] = useState<string | null>(null);
	const [includeInactive, setIncludeInactive] = useState(false);
	const [showEmployeeWithAllocationConfig, setShowEmployeeWithAllocationConfig] = useState(false);

	const [openEmployeeCostModal, setOpenEmployeeCostModal] = useState(false);

	let items = [];

	const payPeriods = useSelector((state: any) => state?.payPeriods?.optionData);

	const dispatch = useDispatch<AppDispatch>();

	const selectedCompanyPermission = useSelector(
		(state: any) => state?.companies?.selectedCompanyDetails?.role?.permissions
	);

	const { data: employeeCosts, notIncludedEmployeeIds } = useSelector((state: any) => state?.employeeCosts);

	const employeeLastSyncDate = useSelector(
		(state: any) =>
			state?.companies?.selectedCompanyDetails?.company?.employeeLastSyncDate
	);

	const isViewPayPeriodPermission = checkPermission(selectedCompanyPermission, {
		permissionName: 'Pay Period',
		permission: ['view'],
	});

	const isAddEmployeeCostPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Employee Cost',
			permission: ['add'],
		}
	);

	const isEditEmployeeCostPermission = checkPermission(
		selectedCompanyPermission,
		{
			permissionName: 'Employee Cost',
			permission: ['edit'],
		}
	);

	const changeEmployeeCost = (isEmployeeCost: string) => {
		setIsEmployeeCost(isEmployeeCost);
	};

	const handleSync = () => {
		setSyncLoading(true);
		postApi(`/employees/sync`, {
			companyId: localStorage.getItem('companyId'),
		})
			.then((data) => {
				dispatch(
					getEmployeeCostAction({
						page: 1,
						limit: 10,
						sort: 'asc',
						payPeriodId: payPeriodId,
						includeInactive,
					})
				);
				dispatch(employeeCostSyncDate());
				dispatch(fetchClosingDateAction({ syncLogs: true }));
				toastText(data.data?.message, 'success');
				setSyncLoading(false);
				// setPayPeriodId(null);
				localStorage.removeItem('payPeriod');
			})
			.catch((err) => {
				toastText(err?.response?.data?.message, 'error');
				setSyncLoading(false);
			});
	};

	const handleInactive = (value: boolean) => {
		setIncludeInactive(value);
	};


	const handleShowEmployeeWithAllocationConfig = (value: boolean) => {
		setShowEmployeeWithAllocationConfig(value);
	};

	const handleEmployeeModalClose = () => {
		setOpenEmployeeCostModal(false);
	}

	useEffect(() => {
		onChangePayPeriod && onChangePayPeriod(payPeriodId);
	}, [payPeriodId]);

	if (isViewPayPeriodPermission) {
		items = [
			{
				key: '1',
				label: 'Employee Cost',
				children: (
					<>
						<div className={styles['employee-costs-export']}>
							<div className={styles['search-filter-second']}>
								{notIncludedEmployeeIds.length > 0 && payPeriodId && (
									<Space className={styles['search-filter']}>
										<button onClick={() => {
											dispatch(updateNotIncludedEmployeeIds({ notIncludedEmployeeIds: [] }))
										}} className='btn-black' >Show All Employees</button>
									</Space>
								)}
								{employeeCosts.length > 0 && payPeriodId && (
									<Space className={styles['search-filter']}>
										<button onClick={() => setOpenEmployeeCostModal(true)} className='btn-black' >Import Employee Cost</button>
									</Space>
								)}
								{(isAddEmployeeCostPermission ||
									isEditEmployeeCostPermission) && (
										<Space className={styles['search-filter']}>
											<SyncNow
												syncDate={lastSyncTime}
												tooltip="Sync Now"
												handleSync={handleSync}
												isLoading={syncLoading}
											/>
										</Space>
									)}
								{employeeCosts.length > 0 && payPeriodId && (
									<Space className={styles['search-filter']}>
										<ExportData
											params={{
												search: searchValue,
												payPeriodId: payPeriodId,
												payPeriodData: payPeriods.find(
													(payPeriod: any) => payPeriod.id === payPeriodId
												),
												includeInactive: includeInactive,
											}}
											moduleName="employeeCost"
										/>
									</Space>
								)}
							</div>
						</div>

						{
							notIncludedEmployeeIds?.length ? (
								<p style={{ padding: '5px 20px', color: '#0000ff' }} >Note: The following employees are missing time entries or need to be configured with a fixed percentage.</p>
							) : null
						}

						<DynamicTable
							onChangePayPeriod={(value: string | null) => {
								setPayPeriodId(value);
							}}
							onChangeSearchValue={(value: string | null) => {
								setSearchValue(value);
							}}
							handleInactive={handleInactive}
							includeInactive={includeInactive}
							onChangeCostValue={(flag: boolean) => {
								onChangeCostValue && onChangeCostValue(flag);
							}}
							onChangeShowEmployeeWithAllocationConfig={handleShowEmployeeWithAllocationConfig}
							showEmployeeWithAllocationConfig={showEmployeeWithAllocationConfig}
							onCloseEmployeeCostModal={handleEmployeeModalClose}
							openEmployeeCostModal={openEmployeeCostModal}
						/>

						{/* {
							openEmployeeCostModal ? (
								<ImportEmployeeCostCsvModal
									open={openEmployeeCostModal}
									onClose={() => handleEmployeeModalClose()}
								/>
							) : null
						} */}

					</>
				),
			},
			{
				key: '2',
				label: 'Pay Period',
				children: <PayPeriod changeEmployeeCost={changeEmployeeCost} />,
			},
		];
	} else {
		items = [
			{
				key: '1',
				label: 'Employee Cost',
				children: (
					<>
						<div className={styles['employee-costs-export']}>
							<div className={styles['search-filter-second']}>
								{notIncludedEmployeeIds.length > 0 && payPeriodId && (
									<Space className={styles['search-filter']}>
										<button onClick={() => {
											dispatch(updateNotIncludedEmployeeIds({ notIncludedEmployeeIds: [] }))
										}} className='btn-black' >Show All Employees</button>
									</Space>
								)}
								{employeeCosts.length > 0 && payPeriodId && (
									<Space className={styles['search-filter']}>
										<button onClick={() => setOpenEmployeeCostModal(true)} className='btn-black' >Import Employee Cost</button>
									</Space>
								)}
								{(isAddEmployeeCostPermission ||
									isEditEmployeeCostPermission) && (
										<Space className={styles['search-filter']}>
											<SyncNow
												syncDate={lastSyncTime}
												tooltip="Sync Now"
												handleSync={handleSync}
												isLoading={syncLoading}
											/>
										</Space>
									)}
								{employeeCosts.length > 0 && payPeriodId && (
									<Space className={styles['search-filter']}>
										<ExportData
											params={{
												search: searchValue,
												payPeriodId: payPeriodId,
												payPeriodData: payPeriods.find(
													(payPeriod: any) => payPeriod.id === payPeriodId
												),
												includeInactive: includeInactive,
											}}
											moduleName="employeeCost"
										/>
									</Space>
								)}
							</div>
						</div>

						{
							notIncludedEmployeeIds?.length ? (
								<p style={{ padding: '5px 20px', color: '#0000ff' }} >Note: The following employees are missing time entries or need to be configured with a fixed percentage.</p>
							) : null
						}

						<DynamicTable
							onChangePayPeriod={(value: string | null) => {
								setPayPeriodId(value);
							}}
							onChangeSearchValue={(value: string | null) => {
								setSearchValue(value);
							}}
							handleInactive={handleInactive}
							includeInactive={includeInactive}
							onChangeCostValue={(flag: boolean) => {
								onChangeCostValue && onChangeCostValue(flag);
							}}
							showEmployeeWithAllocationConfig={showEmployeeWithAllocationConfig}
							onChangeShowEmployeeWithAllocationConfig={handleShowEmployeeWithAllocationConfig}
							onCloseEmployeeCostModal={handleEmployeeModalClose}
							openEmployeeCostModal={openEmployeeCostModal}
						/>

						{/* {
							openEmployeeCostModal ? (
								<ImportEmployeeCostCsvModal
									open={openEmployeeCostModal}
									onClose={() => handleEmployeeModalClose()}
								/>
							) : null
						} */}
					</>
				),
			},
		];
	}

	useEffect(() => {
		setLastSyncTime(employeeLastSyncDate);
	}, [employeeLastSyncDate]);

	return (
		<div className="employee-costs-tabs">
			<Tabs
				defaultActiveKey="2"
				items={items}
				activeKey={isEmployeeCost}
				key={isEmployeeCost}
				tabBarStyle={{
					paddingLeft: '20px',
					fontSize: '18px',
				}}
				onChange={(e) => {
					setIsEmployeeCost(e);
					onChangeKey && onChangeKey(e);
				}}
			//indicatorSize={(origin) => origin - 16}
			/>
		</div>
	);
};

export default EmployeeCosts;
