/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table, Tooltip } from 'antd';
import { Loader } from 'components/Global';
import MessageModal from 'components/Global/MessageModal';
import { useCallback, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { classOptionsAction } from 'redux/action/classAction';
import { getConfiguration } from 'redux/action/configurationAction';
import {
	getCostAllocationAction,
	getPaginationCostAllocationAction,
} from 'redux/action/costAllocationAction';
import {
	customerAccountAction,
	customerOptionsAction,
} from 'redux/action/customerAction';
import { getEmployeeAction } from 'redux/action/employeeAction';
import { getEmployeeCostColumnAction } from 'redux/action/employeeCostColumnSlice';
import { getApi } from 'redux/apis';
import { clearCostAllocationRedux } from 'redux/slice/costAllocationSlice';
import { AppDispatch } from 'redux/store';
import { useDebounce } from 'use-debounce';
import { ClockSvg, SortSvgBottom, SortSvgTop } from 'utils/svgs';
import { convertToHyphenCase, hasText } from 'utils/utils';
import SearchAndFilter from '../SearchAndFilter';
import styles from './index.module.scss';
import './index.scss';
import { CostAllocationTableProps } from './types';
import { CostAllocationDifferenceModal } from '../WarningModal';
import { updateNotIncludedEmployeeIds } from 'redux/slice/employeeCostSlice';

const FIXED_COLUMNS = ['Employee Name', 'Customer Name', 'Class Name'];

const DynamicTable = (props: CostAllocationTableProps) => {
	const { handlePayPeriodId } = props;
	const { Column } = Table;
	const dispatch = useDispatch<AppDispatch>();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const navigate = useNavigate();

	const { data: sectionWiseFields } = useSelector(
		(state: any) => state?.employeeCostColumns
	);

	const { optionData: payPeriods, isPayPeriodOptionFetched } = useSelector(
		(state: any) => state?.payPeriods
	);

	const payrollMethod = useSelector(
		(state: any) => state?.companies?.configurations?.payrollMethod
	);

	const {
		count,
		costAllocationData,
		employeeRowSpanMapping,
		currentDatePayPeriod,
		error: costAllocationError,
		// hasCostAllocationData
	} = useSelector((state: any) => state?.costAllocation);

	const [queryParams, setQueryParams] = useSearchParams();

	const [payPeriodId, setPayPeriodId] = useState<string | null>(
		queryParams.get('payPeriod') || localStorage.getItem('payPeriod') || null
	);

	const [showWarningModel, setShowWarningModel] = useState(false);

	// const tableRef = useRef<HTMLDivElement>(null);
	const [isInViewPort, setIsInViewPort] = useState<boolean>(false);
	const [columns, setColumns] = useState([]);

	const [searchValue, setSearchValue] = useState<string>('');
	const [debounceSearchValue] = useDebounce(searchValue, 1000);
	const [applySearch, setApplySearch] = useState<boolean>(false);

	const [sortBy, setSortBy] = useState<string | null>('fullName');
	const [sortType, setSortType] = useState<string | null>('asc');

	const [filterValue, setFilterValue] = useState<any>({});

	const [
		openCostAllocationDifferenceModal,
		setOpenCostAllocationDifferenceModal,
	] = useState(false);

	const [costAllocationDifferenceData, setCostAllocationDifferenceData] =
		useState<any>(null);

	const [pageNo, setPageNo] = useState(1);

	const { selectedCompanyDetails } = useSelector(
		(state: any) => state.companies
	);

	const { data: configurationsData } = useSelector(
		(state: any) => state?.configuration
	);

	const colorArray = ['cell-fff', 'cell-e7eff8', 'cell-e1f1eb', 'cell-f3ede7'];

	const sortData = {
		showSorterTooltip: { title: '' },
		defaultSortOrder: 'ascend',
		sorter: () => {
			return null as any;
		},
		sortDirections: ['ascend', 'descend', 'ascend'],
		sortIcon: (data: any) => {
			return data.sortOrder === 'ascend' ? <SortSvgTop /> : <SortSvgBottom />;
		},
	};

	const [totalRow, setTotalRow] = useState<any>(null);

	// Get total employee-cost total

	useEffect(() => {
		return () => {
			dispatch(clearCostAllocationRedux());
		};
	}, []);

	useEffect(() => {
		dispatch(
			getConfiguration({
				payPeriodId,
			})
		);

		dispatch(
			getEmployeeCostColumnAction({
				payPeriodId,
			})
		);

		getCostAllocationActionDifference();
		// if (payPeriodId) {
		// }
	}, [payPeriodId]);

	useEffect(() => {
		if (selectedCompanyDetails && payPeriods && isPayPeriodOptionFetched) {
			let _payPeriodId: string | null = null;
			if (payPeriods.length) {
				if (
					payPeriods[payPeriods.length - 1].companyId ===
					selectedCompanyDetails.companyId
				) {
					_payPeriodId = payPeriodId;
					if (_payPeriodId) {
						const payPeriodData = payPeriods.find(
							(e: any) => e.id === payPeriodId
						);
						if (
							!payPeriodData ||
							payPeriodData.companyId != selectedCompanyDetails.companyId
						) {
							_payPeriodId = payPeriods[payPeriods.length - 1]?.id || null;
						}
					} else {
						_payPeriodId = payPeriods[payPeriods.length - 1]?.id || null;
					}
					dispatch(classOptionsAction());
					dispatch(customerAccountAction());
					dispatch(customerOptionsAction());
					dispatch(getEmployeeAction());
					setPayPeriodId(_payPeriodId);
					if (_payPeriodId) {
						setQueryParams({ payPeriodId: _payPeriodId as string });
					}
					setApplySearch(true);
				}
			} else {
				if (_payPeriodId) {
					setQueryParams({ payPeriodId: _payPeriodId as string });
					localStorage.setItem('payPeriod', _payPeriodId as string);
				}
				dispatch(classOptionsAction());
				dispatch(customerAccountAction());
				dispatch(customerOptionsAction());
				dispatch(getEmployeeAction());
				setPayPeriodId(_payPeriodId);
				if (_payPeriodId) {
					setQueryParams({ payPeriodId: _payPeriodId as string });
				}
				setApplySearch(true);
			}
		}
	}, [selectedCompanyDetails, payPeriods, isPayPeriodOptionFetched]);

	useEffect(() => {
		if (currentDatePayPeriod) {
			setPayPeriodId(currentDatePayPeriod);
		}
	}, [currentDatePayPeriod]);

	useEffect(() => {
		if (
			costAllocationError &&
			costAllocationError?.message === 'Invalid PayPeriod' &&
			hasText(payPeriodId)
		) {
			navigate('/unauthorized');
		}
	}, [costAllocationError]);

	useEffect(() => {
		if (payPeriodId) {
			handlePayPeriodId(payPeriodId);
		}
	}, [payPeriodId]);

	useEffect(() => {
		if (applySearch) {
			getCostAllocationData();
		}
	}, [
		payPeriodId,
		debounceSearchValue,
		filterValue,
		sortBy,
		sortType,
		selectedCompanyDetails,
		payPeriods,
		isPayPeriodOptionFetched,
	]);

	useEffect(() => {
		const tableBody = document.querySelector('.ant-table-body');
		tableBody?.addEventListener('scroll', scrollHandler);
		return () => {
			// Remove scroll event listener when component unmounts
			tableBody?.removeEventListener('scroll', scrollHandler);
		};
	}, [costAllocationData]);

	useEffect(() => {
		(async () => {
			if (
				isInViewPort &&
				employeeRowSpanMapping &&
				Object.keys(employeeRowSpanMapping).filter((e) => hasText(e)).length <
					count
			) {
				await getCostAllocationData(true);
			}
		})();
	}, [isInViewPort]);

	useEffect(() => {
		const finalColumns: any = [
			{
				isSectionLastColumn: true,
				className: 'employee-name',
				cellColor: 'cell-fff',
				sectionId: '',
				sectionName: '',
				fieldId: '',
				fieldName: 'Employee Name',
				fieldType: 'Text',
				isCalculatorNeeded: false,
				isDollarNeeded: false,
				isHours: false,
				total: false,
				title: 'Employee Name',
				dataIndex: 'employee-name',
				key: Math.random(),
				width: 200,
				isHoursSetup: false,
				...sortData,
			},
			{
				isSectionLastColumn: false,
				className: 'customer-name',
				cellColor: 'cell-fff',
				sectionId: '',
				sectionName: '',
				fieldId: '',
				fieldName: 'Customer Name',
				fieldType: 'Text',
				isCalculatorNeeded: false,
				isDollarNeeded: false,
				isHours: false,
				total: false,
				title: 'Customer Name',
				dataIndex: 'customer-name',
				key: Math.random(),
				width: 200,
				isHoursSetup: false,
			},
			{
				isSectionLastColumn: true,
				className: 'class-name',
				cellColor: 'cell-fff',
				sectionId: '',
				sectionName: '',
				fieldId: '',
				fieldName: 'Class Name',
				fieldType: 'Text',
				isCalculatorNeeded: false,
				isDollarNeeded: false,
				isHours: false,
				total: false,
				title: 'Class Name',
				dataIndex: 'class-name',
				key: Math.random(),
				width: 200,
				isHoursSetup: false,
			},
			{
				isSectionLastColumn: false,
				className: 'total-hours',
				cellColor: 'cell-FCF9E1',
				sectionId: '',
				sectionName: '',
				fieldId: '',
				fieldName: 'Total Hours',
				fieldType: 'Text',
				isCalculatorNeeded: false,
				isDollarNeeded: false,
				isHours: false,
				total: false,
				title: 'Total Hours',
				dataIndex: 'total-hours',
				key: Math.random(),
				width: 100,
				isHoursSetup: false,
			},
			{
				isSectionLastColumn: true,
				className: 'allocation',
				cellColor: 'cell-FCF9E1',
				sectionId: '',
				sectionName: '',
				fieldId: '',
				fieldName: 'Allocation',
				fieldType: 'Text',
				isCalculatorNeeded: false,
				isDollarNeeded: false,
				isHours: false,
				total: false,
				title: 'Allocation%',
				dataIndex: 'allocation',
				key: Math.random(),
				width: 150,
				isHoursSetup: false,
			},
		];
		// (payrollMethod === 'Percentage' && singleSection.no === 0)
		sectionWiseFields?.map((singleSection: any, sectionIndex: number) => {
			singleSection?.fields?.map((singleField: any, index: number) => {
				if (singleSection.no != 0 && singleField.isActive) {
					finalColumns.push({
						svg:
							sectionIndex === 0 && (index === 1 || index === 2) ? (
								<ClockSvg />
							) : null,
						cellWidth:
							sectionIndex === 0 && (index === 1 || index === 2) ? 130 : 90,
						className: convertToHyphenCase(singleField.name),
						sectionId: singleSection?.id,
						sectionName: singleSection?.sectionName,
						fieldId: singleField?.id,
						fieldName: singleField?.name,
						fieldType:
							singleField?.name == 'Employee Type' ? 'Select' : 'Number',
						isCalculatorNeeded:
							singleSection?.sectionName == 'Employee Type' ||
							payrollMethod === 'Percentage'
								? false
								: true,
						isDollarNeeded:
							singleSection?.sectionName == 'Employee Type' ? false : true,
						isHours:
							singleField?.name == 'Maximum allocate hours per year'
								? true
								: singleField?.name == 'Maximum Vacation/PTO hours per year'
								? true
								: false,
						total: false,
						title: singleField?.name,
						dataIndex: singleField?.id, //name?.split(' ').join('-').toLowerCase()
						cellColor: colorArray[sectionIndex],
						isSectionLastColumn:
							index === singleSection?.fields.length - 1 ? true : false,
						key: singleField?.id,
						width:
							singleField?.name == 'Employee Type'
								? 250
								: singleField?.name == 'Maximum allocate hours per year'
								? 210
								: singleField?.name == 'Maximum Vacation/PTO hours per year'
								? 250
								: 140,
						status: singleField?.active,

						isHoursSetup: singleSection?.no == 0,
					});
				}
			});
		});

		finalColumns.push({
			isSectionLastColumn: false,
			className: 'indirect-allocation',
			cellColor: 'cell-DFE9ED',
			sectionId: '',
			sectionName: '',
			fieldId: '',
			fieldName: 'Indirect Allocation',
			fieldType: 'Number',
			isCalculatorNeeded: false,
			isDollarNeeded: false,
			isHours: false,
			total: false,
			title: 'Indirect Allocation',
			dataIndex: 'indirect-allocation',
			key: Math.random(),
			width: 150,
			isHoursSetup: false,
		});

		setColumns(finalColumns);
	}, [sectionWiseFields, payrollMethod]);

	const scrollHandler = useCallback((event: any) => {
		const { currentTarget } = event;
		const tableBody = currentTarget?.querySelector('tbody');
		if (
			tableBody?.getBoundingClientRect().top +
				tableBody.getBoundingClientRect().height <
			screen.height - 100
		) {
			setIsInViewPort(true);
		} else {
			setIsInViewPort(false);
		}
	}, []);

	const getCostAllocationData = async (isPageable?: boolean) => {
		if (isPageable) {
			setPageNo((prev: any) => prev + 1);
		} else {
			setPageNo(1);
			setTotalRow(null);
		}

		const query: any = {
			page: isPageable ? pageNo + 1 : 1,
			limit: 10,
			search: searchValue,
			payPeriodId,
			sort: sortBy,
			type: sortType,
			...filterValue,
		};

		if (!hasText(query.employeeId)) {
			delete query.employeeId;
		}

		if (!hasText(query.customerId)) {
			delete query.customerId;
		}

		if (!hasText(query.classId)) {
			delete query.classId;
		}

		if (!hasText(searchValue)) {
			delete query.search;
		}

		if (isPageable) {
			await dispatch(getPaginationCostAllocationAction(query));
			return;
		}
		await dispatch(getCostAllocationAction(query));
		try {
			const totalRowRes = await getApi('/cost-allocation/total-row', {
				...query,
				companyId: localStorage.getItem('companyId'),
			});
			setTotalRow(totalRowRes.data?.data);
		} catch (err: any) {
			if (err?.response?.data?.error?.status == 401) {
				navigate('/login');
				window.location.reload();
			}
		}

		setApplySearch(false);
	};

	const tableChangeHandler = (_: unknown, __: unknown, tableData: any) => {
		if (tableData.field && tableData.order) {
			setApplySearch(true);
			setSortBy('fullName');
			setSortType(tableData.order === 'ascend' ? 'asc' : 'desc');
		}
	};

	const handleConfigure = () => {
		dispatch(
			updateNotIncludedEmployeeIds({
				notIncludedEmployeeIds:
					costAllocationDifferenceData.notFoundAllocationEmployeesIDs,
			})
		);
		navigate('/employee-costs');
	};

	const getCostAllocationActionDifference = async () => {
		try {
			const res = await getApi('/cost-allocation/difference', {
				payPeriodId,
				companyId: localStorage.getItem('companyId'),
			});

			if (
				res.data?.data &&
				res.data?.data?.notFoundAllocationEmployeesIDs &&
				res.data?.data?.notFoundAllocationEmployeesIDs?.length
			) {
				setCostAllocationDifferenceData({
					employees: res.data.data?.notFoundAllocationEmployeesName,
					totalAllocation: res.data.data?.totalAllocation,
					totalEmployeeCostWithoutIndirectRate:
						res.data?.data?.totalEmployeeCostWithoutIndirectRate,
					totalEmployeeCost: res.data.data?.totalEmployeeCost,
					notFoundAllocationEmployeesIDs:
						res.data.data?.notFoundAllocationEmployeesIDs,
				});
				setOpenCostAllocationDifferenceModal(true);
			} else {
				setOpenCostAllocationDifferenceModal(false);
			}
		} catch (err: any) {
			if (err?.response?.data?.error?.status == 401) {
				navigate('/login');
				window.location.reload();
			}
		}
	};

	return (
		<>
			{columns ? (
				<div className="cost-allocation-table">
					<SearchAndFilter
						searchValue={searchValue}
						performSearchHandler={(value: any) => {
							setSearchValue(value);
							setApplySearch(true);
						}}
						onChangePayPeriod={(id: any) => {
							setPayPeriodId(id);
							setApplySearch(true);
							setQueryParams({ payPeriod: id });
						}}
						selectedPayPeriod={payPeriodId}
						filterValue={filterValue}
						performFilterHandler={(key: any, value: any) => {
							setFilterValue({ ...filterValue, [key]: value });
							setApplySearch(true);
						}}
						clearFilter={() => {
							setApplySearch(true);
							setSortBy('');
							setSortType('');
							setSearchValue('');
							setFilterValue({});
						}}
						sortBy={sortBy}
						sortType={sortType}
					/>
					<Table
						dataSource={
							totalRow && costAllocationData
								? [...costAllocationData, totalRow]
								: costAllocationData
						}
						scroll={{ y: 'calc(80vh - 315px)' }}
						pagination={false}
						// ref={tableRef}
						rowKey={(record: any) => {
							return record.id;
						}}
						sticky
						footer={() => {
							return (
								<div className="cost-allocation-footer">
									{/* <NumericFormat
									value={grandTotal || 0}
									thousandSeparator=","
									decimalScale={configurationsData?.decimalToFixedAmount || 2}
									fixedDecimalScale={true}
									displayType="text"
									prefix="Grand Total: $"
									renderText={(_value) => (
										<h4
											className={styles['dynamic-table-total']}
										>
											{_value ?? 0}
										</h4>
									)}
								/> */}
								</div>
							);
						}}
						className="cost-allocation"
						rowClassName={(record: any) => {
							if (record.type === 'total') {
								return 'cost-allocation-row';
							} else if (record.type === 'grandTotal') {
								return 'cost-allocation-total-row';
							}
							return '';
						}}
						loading={!costAllocationData}
						onChange={tableChangeHandler}
					>
						{columns?.map((singleColumn: any) => {
							return (
								<Column
									title={() => {
										return (
											<Tooltip>
												<div className="cost-allocation-column-title">
													<p>{singleColumn.svg}</p>
													<p>{singleColumn?.title}</p>
												</div>
											</Tooltip>
										);
									}}
									className={`${
										singleColumn.isSectionLastColumn && 'last-column'
									}`}
									showSorterTooltip={{ title: '' }}
									defaultSortOrder={singleColumn.defaultSortOrder}
									sorter={singleColumn.sorter}
									sortDirections={singleColumn.sortDirections}
									sortIcon={singleColumn.sortIcon}
									dataIndex={singleColumn?.dataIndex}
									key={singleColumn?.key}
									fixed={
										FIXED_COLUMNS.includes(singleColumn?.fieldName) && 'left'
									}
									width={singleColumn?.width}
									onCell={(record: any) => {
										let rowSpan = 1;
										let className = `${singleColumn.className} ${
											singleColumn.isSectionLastColumn && 'last-column'
										} ${singleColumn.cellColor}`;

										if (singleColumn?.dataIndex === 'employee-name') {
											rowSpan = employeeRowSpanMapping[
												record[
													'employee-name'
												] as keyof typeof employeeRowSpanMapping
											]
												? employeeRowSpanMapping[
														record[
															'employee-name'
														] as keyof typeof employeeRowSpanMapping
												  ]
												: 0;
										}

										if (record?.type === 'total') {
											className = `${singleColumn.className} total-row`;
										}

										return {
											rowSpan: rowSpan,
											className,
										};
									}}
									render={(value: string) => {
										return singleColumn?.fieldType === 'Text' ? (
											<span
												className={styles['dynamic-table__employee-name']}
												onClick={() => {
													setShowWarningModel(true);
												}}
											>
												{value?.includes('Total') ? <b>{value}</b> : value}
											</span>
										) : (
											<div className={styles['dynamic-table__status']}>
												<NumericFormat
													value={value}
													thousandSeparator=","
													decimalScale={
														configurationsData?.decimalToFixedAmount || 2
													}
													fixedDecimalScale={true}
													displayType="text"
													prefix="$"
													renderText={(_value) => (
														<h4
															className={styles['dynamic-table-total']}
															style={{
																textAlign: 'right',
															}}
															onClick={() => {
																setShowWarningModel(true);
															}}
														>
															{_value ?? 0}
														</h4>
													)}
												/>
											</div>
										);
									}}
								/>
								// )
							);
						})}
					</Table>
					<p style={{ color: '#818181', padding: '10px', fontSize: '14px' }}>
						Note: Values on this page are read only.
					</p>
				</div>
			) : (
				<div
					style={{
						position: 'fixed',
						top: 'calc(48vh + 70px)',
						left: 'calc(48vw + 150px)',
					}}
				>
					<Loader />
				</div>
			)}
			<MessageModal
				isModalOpen={showWarningModel}
				message="The values on this report are read only. To edit these values, please go to the Employee Cost page and/or the Time Activity page."
				handleCancel={() => {
					setShowWarningModel(false);
				}}
			/>
			{openCostAllocationDifferenceModal ? (
				<CostAllocationDifferenceModal
					isOpen={openCostAllocationDifferenceModal}
					onCancel={() => setOpenCostAllocationDifferenceModal(false)}
					data={costAllocationDifferenceData}
					onConfigure={() => handleConfigure()}
				/>
			) : null}
		</>
	);
};

export default DynamicTable;
